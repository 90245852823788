import React from 'react';
import api from '../common/ApiRequest';
import WithChannels from '../common/providers/WithChannels';
import Notification from '../common/components/Notification';
import auth from '../auth/AuthService';
import BroadcastEditor from './components/BroadcastEditor';
import UserIdContext from '../common/UserIdContext';
import withLocation from '../common/providers/withLocation';
import {sanitize} from './BroadcastService';

class BroadcastCreate extends React.Component {
	
	/**
	 * @param props
	 * @param context
	 */
	constructor(props, context) {
		super(props, context);
		this.state = {
			saving: false,
			error: null,
			model: {
				user: auth.getCurrentUser()._id,
				name: '',
				srcUrl: '',
				livestream: {
					channel: null
				},
				serverType: 'primary'
			}
		}
	}
	
	/**
	 * @param model
	 */
	onSubmit(model) {
		this.setState({
			saving: true
		});
		
		api.put('/apps/broadcast', sanitize(model))
			.then(model => this.props.navigate(`/broadcast/${model._id}?created=1`))
			.catch(e => {
				this.setState({
					error: e.toString(),
					saving: false
				})
			});
	}
	
	/**
	 * @returns {JSX.Element}
	 */
	render() {
		return (
			<UserIdContext.Provider value={auth.getCurrentUser()._id}>
				<Notification
					message={this.state.error}
					onClear={() => this.setState({error: null})}
					severity={'error'}
				/>
				<WithChannels userId={auth.getCurrentUser()._id}>
					<BroadcastEditor
						model={this.state.model}
						onError={error => this.setState({error})}
						onSubmit={this.onSubmit.bind(this)}
						saving={this.state.saving}
					/>
				</WithChannels>
			</UserIdContext.Provider>
		)
	}
}

export default withLocation(BroadcastCreate);
