import React, {useState} from 'react';
import FormControl from '@mui/material/FormControl';
import {TextValidator} from 'react-material-ui-form-validator';
import IconButton from '@mui/material/IconButton';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import {green} from '@mui/material/colors';
import PropTypes from 'prop-types';
import {Box, Paper} from '@mui/material';
import ChipInput from '../../common/components/settings/ChipInput';

export default function AnswerOption(props) {
	const [emptyText, setEmptyText] = useState(!props.text);
	
	const autoAddOption = () => {
		if (emptyText && props.text) {
			setEmptyText(() => false);
			
			if (!props.chatOptions.includes(props.text)) {
				const newOptions = [...props.chatOptions, props.text];
				props.onChange(props.text, props.correct, newOptions);
			}
		}
	}
	
	return (
		<Paper className={`answer-option quiz index-${props.index}`}>
			<Box className={'line1'}>
				<FormControl fullWidth className={'form-control'}>
					<TextValidator
						fullWidth
						required
						variant={'outlined'}
						label={`Option ${props.index}`}
						validators={['required']}
						errorMessages={['Answer Option is required']}
						value={props.text}
						onChange={e => props.onChange(e.target.value, props.correct, props.chatOptions)}
						onBlur={autoAddOption}
					/>
				</FormControl>
				
				{!props.correct && <IconButton
					size='small'
					title={'Mark as correct answer'}
					className={'icon'}
					onClick={() => props.onChange(props.text, true, props.chatOptions)}
					tabIndex={-1}
				>
					<DoneOutlineIcon color={'disabled'}/>
				</IconButton>
				}
				
				{props.correct && <IconButton
					size='small'
					title={'This is the correct answer'}
					className={'icon'}
				>
					<DoneOutlineIcon style={{color: green[500]}}/>
				</IconButton>
				}
			</Box>
			<Box mt={1}>
				<ChipInput
					label={'Chat options'}
					required={true}
					inputProps={{variant: 'standard'}}
					errorMsg={!props.chatOptions.length ? 'Chat options are required' : null}
					onChange={values => props.onChange(props.text, props.correct, values)}
					options={props.chatOptions}
				/>
			</Box>
		</Paper>
	)
}

AnswerOption.propTypes = {
	chatOptions: PropTypes.array,
	index: PropTypes.number.isRequired,
	text: PropTypes.string,
	correct: PropTypes.bool,
	onChange: PropTypes.func.isRequired
}

AnswerOption.defaultProps = {
	chatOptions: [],
	correct: false
}
