import {useState, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';

export function useQueryParams() {
	const [searchParams, setSearchParams] = useSearchParams();
	const objSearchParams = Object.fromEntries([...searchParams]);
	const [data, setData] = useState(objSearchParams);
	
	useEffect(() => {
		setSearchParams(data);
	}, [data]);
	
	useEffect(() => {
		setData(Object.fromEntries([...searchParams]));
	}, [searchParams]);
	
	return [
		data,
		function (param, val) {
			const newSearchParams = {...data};
			newSearchParams[param] = val;
			if (param !== 'page') {
				newSearchParams.page = 1;
			}
			if (+newSearchParams.page === 1) {
				delete newSearchParams.page;
			}
			setData(newSearchParams);
		}
	];
}