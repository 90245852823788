import React, {useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import api from '../common/ApiRequest';
import {Box, LinearProgress} from '@mui/material';
import Notification from '../common/components/Notification';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import PollTable from './components/PollTable';
import {useQueryParams} from '../common/utils/Search';
import FilterBar from './components/FilterBar';

export default function PollList() {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [apps, setApps] = useState(null);
	const [pagination, setPagination] = useState(null);
	const [searchParams, setSearchParams] = useQueryParams();
	const loadData = () => {
		setLoading(true);
		api
			.get(`/apps/poll/`, searchParams)
			.then(result => {
				setApps(() => result.data);
				setPagination(() => result.pagination);
			})
			.catch(e => setError(() => e.toString()))
			.finally(() => setLoading(() => false));
	}
	
	useEffect(() => {
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
		loadData();
	}, [searchParams]);
	
	return (
		<div className={'app-list'}>
			
			<FilterBar loading={loading}/>
			
			<Box sx={{width: '100%'}} style={{visibility: loading ? 'visible' : 'hidden'}}> <LinearProgress/> </Box>
			<Paper>
				
				<PollTable
					apps={apps}
					onError={e => setError(e.toString())}
					onDeleteError={e => setError(e.toString())}
					onDeleteSuccess={loadData}
					onSearchParamsChange={setSearchParams}
					searchParams={searchParams}
				/>
				
				{(pagination && pagination.totalPages > 1) && <Pagination
					className={'pagination'}
					size={'large'}
					color={'primary'}
					page={pagination.page}
					count={pagination.totalPages}
					renderItem={(item) => (
						<PaginationItem
							{...item}
							onClick={() => setSearchParams('page', item.page)}
						/>
					)}
				/>}
			</Paper>
			
			<Notification severity={'error'} message={error} onClear={() => setError(null)}/>
		</div>
	)
}