import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import api from '../ApiRequest';
import {LoadingButton} from '@mui/lab';
import {filesize} from 'filesize';

export default function UploadButton(props) {
	
	const [uploading, setUploading] = useState(false);
	const [target, setTarget] = useState(null);
	
	const handleUpload = e => {
		if (uploading || !e.target) {
			return;
		}
		
		const size = e.target.files[0].size;
		if (props.maxSize && size > props.maxSize) {
			props.onError(`Max file size is ${filesize(props.maxSize)}`);
			return;
		}
		
		setUploading(true);
		setTarget(() => e.target);
		
		if (props.onStart) {
			props.onStart();
		}
	}
	
	useEffect(() => {
		let active = true;
		
		if (target) {
			(async () => {
				try {
					const response = await api.upload(props.uploadUrl, target.files[0], props.uploadData);
					if (active) {
						setUploading(() => false);
						props.onComplete(response);
					}
				} catch (e) {
					if (active) {
						props.onError(e);
					}
				} finally {
					setUploading(() => false);
					target.value = null;
					setTarget(() => null);
				}
			})();
		}
		
		return () => {
			active = false;
		}
	}, [target]);
	
	return (
		<div className={props.className}>
			<input
				accept={props.accept}
				id={props.id}
				type='file'
				style={{display: 'none'}}
				onChange={handleUpload}
				disabled={props.disabled}
			/>
			{<label htmlFor={props.id}>
				<LoadingButton
					variant='contained'
					color={props.color}
					loading={uploading}
					component={'span'}
					disabled={props.disabled}
				>
					{props.title}
				</LoadingButton>
			</label>}
		</div>
	)
}

UploadButton.propTypes = {
	accept: PropTypes.string,
	className: PropTypes.string,
	color: PropTypes.string,
	disabled: PropTypes.bool,
	id: PropTypes.string,
	maxSize: PropTypes.number,
	onComplete: PropTypes.func.isRequired,
	onError: PropTypes.func.isRequired,
	onStart: PropTypes.func,
	title: PropTypes.string,
	uploadData: PropTypes.object,
	uploadUrl: PropTypes.string
}

UploadButton.defaultProps = {
	accept: 'image/*',
	className: '',
	color: 'primary',
	disabled: false,
	id: 'contained-button-file',
	maxSize: +process.env.REACT_APP_IMG_MAX_SIZE,
	title: 'Upload Image',
	uploadUrl: '/image/upload'
}