import React, {useContext, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';
import SettingsFormControl from '../../common/components/settings/SettingsFormControl';
import UserIdContext from '../../common/UserIdContext';
import ChipInput from '../../common/components/settings/ChipInput';
import Grid from '@mui/material/Grid';
import ImagePicker from '../../common/components/media/ImagePicker';
import AppColorPicker from '../../common/components/settings/AppColorPicker';

export default function TeamForm(props) {
	const [userId] = useContext(UserIdContext);
	const [team, setTeam] = useState(props.team);
	const [fieldsChanged, setFieldsChanged] = useState([]);
	
	const handleChange = (field, value) => {
		setTeam(prevObj => ({
			...prevObj,
			[field]: value
		}));
		setFieldsChanged(prevArray => ([
			...prevArray,
			field
		]));
	}
	
	const handleSubmit = () => {
		if (!team.chatOptions || !team.chatOptions.length) {
			if (!fieldsChanged.includes('chatOptions')) {
				setFieldsChanged([fieldsChanged, 'chatOptions']);
			}
			return;
		}
		props.onSubmit({...team});
	}
	
	const validateChatCommands = () => {
	
	}
	
	return (
		<Dialog open={true} onClose={() => props.onCancel()} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'sm'} className={'quiz-team-form'}>
			<ValidatorForm noValidate autoComplete={'off'} onSubmit={handleSubmit}>
				<DialogTitle id={'form-dialog-title'}>{props.title}</DialogTitle>
				<DialogContent dividers>
					<SettingsFormControl>
						<TextValidator
							fullWidth
							required
							variant={'outlined'}
							label={'Name'}
							validators={['required']}
							errorMessages={['Name is required']}
							value={team.name}
							onChange={e => handleChange('name', e.target.value)}
						/>
					</SettingsFormControl>
					
					<SettingsFormControl>
						<AppColorPicker
							variant={'outlined'}
							label={'Team Color'}
							value={team.color}
							TextFieldProps={{value: team.color, fullWidth: true}}
							onChange={value => handleChange('color', value)}
						/>
					</SettingsFormControl>
					
					<SettingsFormControl>
						<ChipInput
							label={'Chat Commands'}
							inputProps={{variant: 'outlined', size: 'normal', helperText: 'Enter chat option and press ENTER'}}
							required={true}
							errorMsg={(fieldsChanged.includes('chatOptions') && !team.chatOptions.length) ? 'Chat commands are required' : null}
							onChange={values => handleChange('chatOptions', values)}
							options={team.chatOptions}
						/>
					</SettingsFormControl>
					
					<Grid container justifyContent={'center'}>
						<Grid item xs={6}>
							<ImagePicker
								userId={userId}
								title={'Team Image'}
								img={team.img}
								onSelect={img => handleChange('img', img)}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				
				<DialogActions className={'actions'}>
					<Button color={'secondary'} variant={'contained'} onClick={() => props.onCancel()}>Cancel</Button>
					<Button className={'save-btn'} variant={'contained'} type={'submit'}>{props.btnTitle}</Button>
				</DialogActions>
			</ValidatorForm>
		</Dialog>
	)
}

TeamForm.propTypes = {
	team: PropTypes.object.isRequired,
	onCancel: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	btnTitle: PropTypes.string,
	title: PropTypes.string.isRequired
}

TeamForm.defaultProps = {
	btnTitle: 'Save'
}