import React, {useState} from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import {useSearchParams} from 'react-router-dom';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import AddChannelButton from './AddChannelButton';

export default function FilterBar(props) {
	const [searchParams, setSearchParams] = useSearchParams();
	const [params, setParams] = useState({
		keyword: searchParams.get('keyword') || '',
		network: searchParams.get('network') || 'any',
		status: searchParams.get('status') || 'any',
		sort: searchParams.get('sort') || 'name-asc',
		t: 0
	});
	
	const onReset = () => {
		const t = +searchParams.get('t') || 0;
		setParams({
			keyword: '',
			status: 'any',
			sort: 'name-asc',
			t: 0
		});
		setSearchParams({t: t + 1});
	}
	
	const onSubmit = (e) => {
		e.preventDefault();
		params.t++;
		setSearchParams(params);
	}
	
	return (
		<form onSubmit={onSubmit}>
			<Paper className={'filter-bar'}>
				
				<div className={'section-label'}>Filter Channels:</div>
				
				<FormControl>
					<TextField
						label={'Search'}
						size={'small'}
						fullWidth
						value={params.keyword}
						onChange={e => setParams(prev => ({...prev, keyword: e.target.value}))}
					/>
				</FormControl>
				
				<FormControl>
					<InputLabel id={'filter-network'}>Type</InputLabel>
					<Select
						fullWidth
						labelId={'filter-network'}
						label={'Type'} size={'small'} value={'any'}
						value={params.network}
						onChange={e => setParams(prev => ({...prev, network: e.target.value}))}
					>
						<MenuItem value={'any'}>Any</MenuItem>
						<MenuItem value={'youtube'}>YouTube</MenuItem>
						<MenuItem value={'twitch'}>Twitch</MenuItem>
					</Select>
				</FormControl>
				
				<FormControl>
					<InputLabel id={'filter-status'}>Status</InputLabel>
					<Select
						fullWidth
						labelId={'filter-status'}
						label={'Status'} size={'small'} value={'any'}
						value={params.status}
						onChange={e => setParams(prev => ({...prev, status: e.target.value}))}
					>
						<MenuItem value={'any'}>Any</MenuItem>
						<MenuItem value={'healthy'}>Healthy</MenuItem>
						<MenuItem value={'unhealthy'}>Unhealthy</MenuItem>
					</Select>
				</FormControl>
				
				<div className={'section-label'}>Sort By:</div>
				
				<FormControl>
					<InputLabel id={'sort-by'}>Sort</InputLabel>
					<Select
						fullWidth
						labelId={'sort-by'}
						label={'Sort'} size={'small'} value={'name-asc'}
						value={params.sort}
						onChange={e => setParams(prev => ({...prev, sort: e.target.value}))}
					>
						<MenuItem value={'name-asc'}>Name A-Z</MenuItem>
						<MenuItem value={'created-desc'}>Recent</MenuItem>
						<MenuItem value={'created-asc'}>Oldest</MenuItem>
					</Select>
				</FormControl>
				
				<Button variant={'contained'} disabled={props.loading} type={'submit'}>Search</Button>
				<Button variant={'contained'} disabled={props.loading} onClick={onReset} color={'secondary'} className={'clear-btn'}>Clear</Button>
				
				<AddChannelButton/>
			</Paper>
		</form>
	);
}

FilterBar.propTypes = {
	loading: PropTypes.bool
}

FilterBar.defaultProps = {
	loading: false
}