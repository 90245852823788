import React from 'react';
import Header from '../components/Header';
import {Outlet} from 'react-router';
import PrivateRoute from '../components/PrivateRoute';
import Container from '@mui/material/Container';

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';

export default function AdminLayout(props) {
	return (
		<PrivateRoute outputType={'children'}>
			<div css={css `
			flex-grow:1;
			.container {
				margin-top: 20px;
				padding: 0;
			}`}>
				<Header/>
				<Container className={'container'} maxWidth={'xl'}>
					{props.children}
					<Outlet/>
				</Container>
			</div>
		</PrivateRoute>
	);
}
