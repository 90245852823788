import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import authService from '../../auth/AuthService';
import Alert from '@mui/material/Alert';
import PropTypes from 'prop-types';

export default function PrivateRoute({children, outputType, roles, permission}) {
	const currentUser = authService.getCurrentUser();
	if (!currentUser) {
		// not logged in so redirect to login page with the return url
		return <Navigate to={'/auth/signin'}/>
	}
	
	let allowed = false;
	
	// check if route is restricted by role
	if (roles && roles.includes(currentUser.role)) {
		allowed = true
	} else if (permission && currentUser.permissions && currentUser.permissions[permission]) {
		allowed = true;
	} else if(!roles && !permission){
		allowed = true;
	}

	// authorised so return component
	return (
		!allowed ? <Alert severity={'error'}>Permission Denied</Alert> :
			<>
				{outputType === 'children' && children}
				{outputType === 'outlet' && <Outlet/>}
			</>
	)
};

PrivateRoute.propTypes = {
	outputType: PropTypes.string
}
PrivateRoute.defaultProps = {
	outputType: 'outlet'
}