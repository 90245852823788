import React, {useEffect, useState} from 'react';
import objectPath from 'object-path';
import QuestionsTab from '../tabs/QuestionsTab';
import TeamsTab from '../tabs/TeamsTab';
import SettingsTab from '../tabs/SettingsTab';
import AppBar from '@mui/material/AppBar';
import StyleTab from '../tabs/StyleTab';
import InfoTab from '../tabs/InfoTab';
import PropTypes from 'prop-types';
import ConfirmDialog from './ConfirmDialog';
import {useLocation, useNavigate} from 'react-router-dom';
import {Routes, Route} from 'react-router-dom';
import MainContainer from '../../common/components/MainContainer';
import QuizTabs from './QuizTabs';
import {trim} from '../../common-libs/utils/String';

export default function QuizEditor(props) {
	const navigate = useNavigate();
	const location = useLocation();
	const [selectedTab, setSelectedTab] = React.useState(null);
	const [model, setModel] = useState(props.model);
	
	// Confirmation dialog.
	const [doNotAskAgain, setDoNotAskAgain] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	
	/**
	 * Update selected tab once location is changed.
	 */
	useEffect(() => {
		const urlPathParts = trim(location.pathname, '/').split('/');
		setSelectedTab(urlPathParts.length === 3 ? urlPathParts.pop() : null);
	}, [location]);
	
	const modelChanged = (prop, value) => {
		const newModel = {...model};
		objectPath.set(newModel, prop, value);
		setModel(newModel);
	};
	
	const onTabClick = path => {
		model._id && navigate(`/quiz/${model._id}/${path}`);
	}
	
	const onSubmit = () => {
		if (!model.name || !model.name.trim()) {
			return props.onError('Name is required');
		} else if (model.status === 'running' && !doNotAskAgain) {
			setShowDialog(true);
		} else if (props.onSubmit) {
			props.onSubmit(model);
		}
	};
	
	const onDialogSubmit = doNotAsk => {
		if (doNotAsk) {
			setDoNotAskAgain(() => true);
		}
		if (props.onSubmit) {
			props.onSubmit(model);
		}
		setShowDialog(() => false);
	};
	
	return (
		<MainContainer className={'app-editor quiz'}>
			{showDialog && <ConfirmDialog onCancel={() => setShowDialog(false)} onSubmit={onDialogSubmit}/>}
			
			<AppBar position={'sticky'} color={'default'}>
				<QuizTabs onClick={onTabClick} selected={selectedTab} mode={model._id ? 'edit' : 'add'}/>
			</AppBar>
			
			<Routes>
				<Route path={'/questions'} element={<QuestionsTab model={model}/>}/>
				<Route path={'/teams'} element={<TeamsTab
					model={model}
					saving={props.saving}
					onChange={modelChanged}
					onSubmit={onSubmit}
				/>}/>
				<Route path={'/styles/*'} element={<StyleTab
					model={model}
					saving={props.saving}
					onChange={modelChanged}
					onSubmit={onSubmit}
				/>}/>
				<Route path={'/how-to-stream'} element={<InfoTab model={model}/>}/>
				<Route index element={<SettingsTab
					model={model}
					channels={props.channels}
					saving={props.saving}
					onChange={modelChanged}
					onError={props.onError}
					onSubmit={onSubmit}
				/>}/>
			</Routes>
		</MainContainer>
	)
}

QuizEditor.propTypes = {
	model: PropTypes.object.isRequired,
	saving: PropTypes.bool,
	onSubmit: PropTypes.func.isRequired,
	onError: PropTypes.func.isRequired
};

QuizEditor.defaultProps = {
	saving: false
}