import React, {useContext, useEffect, useState} from 'react';
import authService from '../auth/AuthService';
import {Box} from '@mui/material';
import Welcome from './boxes/Welcome';
import UserContext from '../common/UserContext';
import Notification from '../common/components/Notification';

import './style.scss';

export default function Dashboard() {
	const [error, setError] = useState(null);
	const user = authService.getCurrentUser();
	const [, setUser] = useContext(UserContext);
	
	/**
	 * Load resources for the page.
	 */
	useEffect(() => {
		authService.verifySession()
			.then(user => setUser(user))
			.catch(e => setError(() => e.toString()));
		
	}, []);
	
	return (
		<Box className={'dashboard'}>
			<Welcome user={user}/>
			<Notification severity={'error'} message={error} onClear={() => setError(null)}/>
		</Box>
	)
}