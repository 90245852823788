import React from 'react';
import PropTypes from 'prop-types';

const outputNumber = (n, prependZero) => {
	n = Math.floor(n);
	return prependZero ? (n < 10 ? `0${n}` : n) : n;
}

export default function HumanTime(props) {
	
	const prependZero = props.prependZero;
	let timeLeftInSeconds = props.time;
	
	let hours = Math.floor(timeLeftInSeconds / 3600);
	timeLeftInSeconds -= hours * 3600;
	
	let minutes = Math.floor(timeLeftInSeconds / 60);
	timeLeftInSeconds -= minutes * 60;
	
	let seconds = Math.floor(timeLeftInSeconds);
	
	return (
		<>
			{hours > 0 && `${outputNumber(hours, prependZero)}:`}{outputNumber(minutes, prependZero)}:{outputNumber(seconds, prependZero)}
		</>
	);
}

HumanTime.propTypes = {
	prependZero: PropTypes.bool,
	time: PropTypes.number.isRequired
}

HumanTime.defaultProps = {
	prependZero: true
}