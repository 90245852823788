import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import sleep from '../../common-libs/utils/sleep';

export default function Scale(props) {
	const screenRef = useRef(null);
	
	const scaleEl = async (screenRef, selector, originWidth) => {
		await sleep(props.delay);
		
		if (screenRef && screenRef.current) {
			const screen = screenRef.current.querySelector(selector);
			if (screen) {
				const scale = (screenRef.current.clientWidth) / originWidth;
				screen.style.transform = `scale(${scale})`;
				screenRef.current.style.opacity = 1;
				props.onScale && props.onScale(scale);
			}
		}
	}
	
	useEffect(() => {
		(async () => await scaleEl(screenRef, props.selector, props.originalWidth))();
	});
	
	return (
		<div ref={screenRef} className={props.className} style={{opacity: 0}}>
			{props.children}
		</div>
	);
}

Scale.propTypes = {
	className: PropTypes.string,
	delay: PropTypes.number,
	onScale: PropTypes.func,
	originalWidth: PropTypes.number,
	selector: PropTypes.string.isRequired
};

Scale.defaultProps = {
	className: '',
	delay: 0,
	originalWidth: 1280,
}
