import React, {useEffect} from 'react';
import SettingsBox from '../../common/components/settings/SettingsBox';
import TextField from '@mui/material/TextField';
import SettingsFormControl from '../../common/components/settings/SettingsFormControl';
import {plural} from '../../common-libs/utils/String';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function ScoresBox(props) {
	
	const model = props.model;
	const onChange = props.onChange;
	
	const maxScore = model.maxScore;
	const minScore = model.minScore;
	
	useEffect(() => {
		if (typeof minScore === 'undefined') {
			return onChange('minScore', 1);
		}
		if (minScore > maxScore) {
			return onChange('minScore', maxScore);
		}
	}, [maxScore]);
	
	return (<SettingsBox title={'Scores Screen'}>
		<SettingsFormControl>
			<TextField required variant='outlined'
			           type='number'
			           InputProps={{
				           inputProps: {
					           min: 10
				           }
			           }}
			           label='Duration'
			           helperText='Time in seconds'
			           value={model.screens.scores.duration}
			           onChange={e => onChange('screens.scores.duration', e.target.value)}
			/>
		</SettingsFormControl>
		<SettingsFormControl>
			<TextField required variant='outlined'
			           type='number'
			           InputProps={{
				           inputProps: {
					           min: 1
				           }
			           }}
			           label='Max Score'
			           helperText='The score for the first correct answer'
			           value={model.maxScore}
			           onChange={e => onChange('maxScore', e.target.value)}
			/>
		</SettingsFormControl>
		<SettingsFormControl>
			<TextField required variant='outlined'
			           type='number'
			           InputProps={{
				           inputProps: {
					           min: 0
				           }
			           }}
			           label='Score Step'
			           helperText={`First user will get ${plural('point', model.maxScore)}, second - ${plural('point', Math.max(minScore, model.maxScore - model.scoreStep))}, third - ${plural('point', Math.max(minScore, model.maxScore - 2 * model.scoreStep))}...`}
			           value={model.scoreStep}
			           onChange={e => onChange('scoreStep', e.target.value)}
			/>
		</SettingsFormControl>
		<SettingsFormControl>
			<TextField required variant='outlined'
			           type='number'
			           InputProps={{
				           inputProps: {
					           min: 0
				           }
			           }}
			           label='Min Score'
			           helperText='The score for the last correct answer'
			           value={minScore}
			           onChange={e => onChange('minScore', Math.min(e.target.value, model.maxScore))}
			/>
		</SettingsFormControl>
		<SettingsFormControl>
			<TextField required variant='outlined'
			           type='number'
			           InputProps={{
				           inputProps: {
					           max: 0
				           }
			           }}
			           label='Penalty Score'
			           helperText='The score for the wrong answer'
			           value={model.penaltyScore}
			           onChange={e => onChange('penaltyScore', e.target.value)}
			/>
		</SettingsFormControl>
		<SettingsFormControl variant={'outlined'}>
			<InputLabel required style={{backgroundColor: '#fff'}}>Default Screen</InputLabel>
			<Select
				required
				value={model.defaultScoreScreen || 'user'}
				onChange={e => onChange('defaultScoreScreen', e.target.value)}
			>
				<MenuItem key={`st1`} value='user'>User's Scores</MenuItem>
				<MenuItem key={`st2`} value='team'>Teams' Scores</MenuItem>
			</Select>
		</SettingsFormControl>
	</SettingsBox>)
}