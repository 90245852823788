import React, {useState} from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import MediaGallery from './MediaGallery';
import VideocamIcon from '@mui/icons-material/Videocam';
import ImageIcon from '@mui/icons-material/Image';

export default function MediaPicker(props) {
	const media = props.media;
	const [mediaOpen, setMediaOpen] = useState(false);
	let imgSrc;
	if (media) {
		if (media.mediaType === 'image' && media.image) {
			imgSrc = media.image.thumbSrc;
		} else if (media.mediaType === 'video' && media.video) {
			imgSrc = media.video.thumbnails.small;
		}
	}
	
	const onSelect = media => {
		setMediaOpen(() => false);
		props.onSelect && props.onSelect(media);
	}
	
	return (
		<>
			<div className={`media-picker ${props.className}`}>
				{media && (
					<Card className={'card'}>
						<CardActionArea>
							<CardMedia
								className={'media-card'}
								image={imgSrc}
								onClick={() => setMediaOpen(true)}
							/>
							{media.mediaType === 'video' ? <VideocamIcon color={'primary'} className={'icon'}/> : <ImageIcon color={'primary'} className={'icon'}/>}
						</CardActionArea>
						
						<CardActions className={'actions'}>
							<div className={'buttons'}>
								<Button size='small' color='secondary' onClick={() => props.onSelect(null)}>Remove</Button>
								<Button size='small' color='primary' onClick={() => setMediaOpen(true)}>Change</Button>
							</div>
						</CardActions>
					</Card>
				)}
				
				{!media && <Button variant={'contained'} onClick={() => setMediaOpen(true)}>{props.title}</Button>}
			</div>
			
			{mediaOpen && <MediaGallery
				userId={props.userId}
				open={true}
				onClose={() => setMediaOpen(false)}
				onSelect={onSelect}
				media={media}
				types={props.types}
			/>}
		</>
	)
}

MediaPicker.propTypes = {
	className: PropTypes.string,
	media: PropTypes.object,
	onSelect: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	types: PropTypes.array,
	userId: PropTypes.string.isRequired
}

MediaPicker.defaultProps = {
	className: '',
	title: 'Select Media',
	types: ['image', 'video']
}