import React from 'react';
import {Typography} from '@mui/material';
import PropTypes from 'prop-types';
import {getLiveStreamURL} from '../utils/livestream';
import SocialIcon from './SocialIcon';

export default function StreamLink(props) {
	const livestream = props.livestream;
	const url = getLiveStreamURL(livestream);
	
	return (
		<div className={'livestream-link'}>
			{url && (
				<a
					href={url}
					target={'_blank'}
					rel={'noreferrer'}
					className={props.className}
				>
					<SocialIcon type={livestream.channel.socialType} size={'1x'}/>
					<Typography variant={props.variant}>{livestream.channel.socialType === 'youtube' ? livestream.title : livestream.channel.title}</Typography>
				</a>
			)}
		</div>
	)
}

StreamLink.propTypes = {
	className: PropTypes.string,
	livestream: PropTypes.object,
	variant: PropTypes.string
}

StreamLink.defaultProps = {
	className: '',
	livestream: null,
	variant: 'body2'
}
