import React, {useEffect, useState} from 'react';
import ActionBar from '../components/ActionBar';
import {Button} from '@mui/material';
import GroupIcon from '@mui/icons-material/Groups';
import Grid from '@mui/material/Grid';
import MainContainer from '../../common/components/MainContainer';
import TeamCard from '../components/TeamCard'
import TeamForm from '../components/TeamForm';
import {v4 as uuidv4} from 'uuid';
import {Alert} from '@mui/lab';
import Box from '@mui/material/Box';
import QuizPicker from '../components/QuizPicker';

export default function TeamsTab(props) {
	
	const model = props.model;
	const teams = model.teams || [];
	const [addingNew, setAddingNew] = useState(false);
	const [teamInEdit, setTeamInEdit] = useState(null);
	const [error, setError] = useState(null);
	
	/**
	 * @param team
	 */
	const addTeam = (team) => {
		props.onChange('teams', [...teams, team]);
		setAddingNew(false);
		validateChatOptions();
	}
	
	/**
	 * @return {{img: null, color: string, name: string, chatOptions: *[], id: (`${string}-${string}-${string}-${string}-${string}`|string|*|string)}}
	 */
	const createEmptyTeam = () => ({id: uuidv4(), name: '', color: '#000', chatOptions: [], img: null});
	
	/**
	 * @param team
	 */
	const deleteTeam = team => {
		props.onChange('teams', teams.filter(t => t.id !== team.id));
	}
	
	/**
	 * @param team
	 */
	const updateTeam = team => {
		const index = teams.findIndex(t => t.id === team.id);
		if (index !== -1) {
			teams[index] = team;
			props.onChange('teams', [...teams]);
		}
		setTeamInEdit(null);
		validateChatOptions();
	}
	
	/**
	 * Make sure all chat option are unique.
	 *
	 * @return {boolean}
	 */
	const validateChatOptions = () => {
		const options = new Set();
		for (let i = 0; i < teams.length; i++) {
			for (let j = 0; j < teams[i].chatOptions.length; j++) {
				const option = teams[i].chatOptions[j];
				if (options.has(option)) {
					setError(`Chat option '${option}' is already in use`);
					return false;
				}
				options.add(option);
			}
		}
		
		setError(null);
		return true;
	}
	
	/**
	 * Make sure all teams have IDs.
	 */
	useEffect(() => {
		teams.forEach(t => {
			t.id = t._id || uuidv4();
		});
	}, [props.model.teams]);
	
	return <div className={'teams-tab'}>
		<ActionBar saving={props.saving} type={'button'} onSubmit={props.onSubmit}>
			
			<Box pr={2}>
				<QuizPicker
					ignoreQuizIds={[model._id]}
					onChange={inheritTeamsFrom => props.onChange('inheritTeamsFrom', inheritTeamsFrom)}
					selected={props.model.inheritTeamsFrom}
				/>
			</Box>
			
			<Button
				disabled={!!props.model.inheritTeamsFrom}
				variant={'contained'}
				color={'primary'}
				className={'add-team-btn'}
				startIcon={<GroupIcon/>}
				onClick={() => setAddingNew(true)}
			>
				Add Team
			</Button>
		</ActionBar>
		
		{!props.model.inheritTeamsFrom && <MainContainer>
			{error && <Alert severity={'error'} style={{marginBottom: '24px'}}>{error}</Alert>}
			<Grid container spacing={2} justifyContent={'center'}>
				{teams.map((team, i) => (<Grid item xs={3} key={`tc-${i}`}>
					<TeamCard
						team={team}
						onEdit={() => setTeamInEdit(team)}
						onDelete={() => deleteTeam(team)}
					/>
				</Grid>))}
			</Grid>
		</MainContainer>}
		
		{addingNew && <TeamForm
			title={'Add Team'}
			team={createEmptyTeam()}
			btnTitle={'Add'}
			onCancel={() => setAddingNew(false)}
			onSubmit={addTeam}
		/>}
		
		{teamInEdit && <TeamForm
			title={'Edit Team'}
			team={teamInEdit}
			btnTitle={'Save'}
			onCancel={() => setTeamInEdit(null)}
			onSubmit={updateTeam}
		/>}
	</div>
}