import React, {useEffect, useState} from 'react';
import api from '../ApiRequest';
import PagePreloader from '../components/PagePreloader';
import PropTypes from 'prop-types';

export default function WithChannels(props) {
	
	const userId = props.userId;
	const [channels, setChannels] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
	
	useEffect(() => {
		api.get(`/channel/`, {perPage: 1000})
			.then(response => {
				if (response && response.data) {
					setChannels(() => response.data.map(row => ({
						_id: row._id,
						title: row.title,
						socialType: row.socialType
					})));
				}
			})
			.catch(e => setError(() => e.toString()))
			.finally(() => setLoading(() => false));
	}, [userId]);
	
	if (error || loading) {
		return <PagePreloader error={error}/>
	} else {
		const newProps = {...props};
		delete newProps.children;
		return React.cloneElement(props.children, {...newProps, channels: channels});
	}
}

WithChannels.propTypes = {
	userId: PropTypes.string.isRequired
}