import React, {useRef, useState} from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import api from '../../common/ApiRequest';
import PropTypes from 'prop-types';
import AjaxButton from '../../common/buttons/AjaxButton';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import Button from '@mui/material/Button';

function CustomPromise() {
	this.resolve = null;
	this.reject = null;
	this.promise = new Promise((resolve, reject) => {
		this.resolve = resolve;
		this.reject = reject;
	});
}

export default function StartButton(props) {
	
	const app = props.app;
	const [dialogOpen, setDialogOpen] = useState(false);
	const promise = useRef(null);
	
	/**
	 * Ajax button exec callback.
	 */
	const ajaxBtnCallback = () => {
		if (app.runningState) {
			setDialogOpen(true);
			promise.current = new CustomPromise();
			return promise.current.promise;
		} else {
			return sendRequest(false);
		}
	}
	
	/**
	 * @param reset
	 * @returns {Promise<void>}
	 */
	const dialogCallback = async reset => {
		setDialogOpen(false);
		sendRequest(reset)
			.then(() => promise.current.resolve())
			.catch((e) => promise.current.reject(e))
	}
	
	/**
	 * Send request to the server.
	 *
	 * @param reset
	 * @returns {Promise<Response>}
	 */
	const sendRequest = reset => {
		return api.post(`/apps/poll/${props.app._id}/start`, {reset});
	}
	
	return (
		<>
			<Dialog open={dialogOpen}>
				<DialogTitle>Reset the votes?</DialogTitle>
				<DialogContent>
					<DialogContentText>
						This poll has votes from the previous run. Do you want to reset them?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => dialogCallback(true)} autoFocus>Yes, delete the votes</Button>
					<Button onClick={() => dialogCallback(false)}>No, keep the votes</Button>
				</DialogActions>
			</Dialog>
			
			<AjaxButton
				ariaLabel={'start'}
				color={'secondary'}
				exec={ajaxBtnCallback}
				icon={PlayArrowIcon}
				{...props}
			/>
		</>
	);
}

StartButton.propTypes = {
	app: PropTypes.object.isRequired,
	title: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	onError: PropTypes.func
}

StartButton.defaultProps = {
	title: 'Start',
	disabled: false
}