import React, {useState} from 'react';
import ColorPicker from './mui-color-picker/ColorPicker';

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';

export default function AppColorPicker(props) {
	const [open, setOpen] = useState(false);
	return (
		<div css={css`
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: space-between;
		.picker {
			flex-grow: 1;
			margin-right: 1rem;
		}
		.preview {
			width: 1.5rem;
			height: 1.5rem;
			padding: 3px;
			border-radius: 4px;
			border: solid 1px #c4c4c4;
			cursor: pointer;
		}
		`} className={`app-color-picker ${props.className}`}>
			<ColorPicker
				{...props}
				className={'picker'}
				open={open}
				onClose={() => setOpen(false)}
				onOpen={() => setOpen(true)}
			/>
			<div className={'preview'} style={{backgroundColor: props.value}} onClick={() => setOpen(true)}></div>
		</div>
	)
}