/**
 * @param livestream
 * @return {string|*|null}
 */
export function getLiveStreamURL(livestream) {
	if (!livestream) {
		return null;
	}
	
	const channel = livestream.channel;
	if (!channel) {
		return null;
	}
	
	if (channel.socialType === 'youtube') {
		return `https://youtube.com/watch?v=${livestream.ytStreamId}`;
	} else if (channel.socialType === 'twitch') {
		return channel.url;
	}
	
	return null;
}
