import React from 'react';
import {TextValidator} from 'react-material-ui-form-validator';
import SettingsFormControl from './SettingsFormControl';
import PropTypes from 'prop-types';

export default function SizePicker(props) {
	
	const onChange = e => {
		if (!props.onChange) {
			return;
		}
		
		let val = +e.target.value;
		if (val > props.max) {
			val = props.max;
		}
		
		props.onChange(val);
	}
	
	return (
		<SettingsFormControl>
			<TextValidator
				fullWidth
				variant='outlined'
				type='number'
				InputProps={{
					inputProps: {
						min: props.min,
						max: props.max
					}
				}}
				label={props.label}
				value={props.value}
				validators={[`minNumber:${props.min}`, `maxNumber:${props.max}`]}
				errorMessages={[`Minimum value is ${props.min}`, `Maximum value is ${props.max}`]}
				onChange={onChange}
			/>
		</SettingsFormControl>
	)
}

SizePicker.propTypes = {
	label: PropTypes.string,
	value: PropTypes.number,
	min: PropTypes.number,
	max: PropTypes.number,
	onChange: PropTypes.func
}

SizePicker.defaultProps = {
	label: 'Font Size',
	min: 50,
	max: 200
}