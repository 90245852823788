import React, {useContext, useState} from 'react';
import MediaPicker from '../../common/components/media/MediaPicker';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import Grid from '@mui/material/Grid';
import AnswerOption from './AnswerOption';
import PropTypes from 'prop-types';
import SettingsFormControl from '../../common/components/settings/SettingsFormControl';
import UserIdContext from '../../common/UserIdContext';
import Notification from '../../common/components/Notification';
import LoadingButton from '@mui/lab/LoadingButton';

function mediaToJson(media) {
	if (media && media.mediaType === 'image' && media.image) {
		return {mediaType: 'image', image: media.image._id};
	} else if (media && media.mediaType === 'video' && media.video) {
		return {mediaType: 'video', video: media.video._id};
	}
	return null;
}

export default function QuestionForm(props) {
	
	const [question, setQuestion] = useState(props.question);
	const [error, setError] = useState('');
	const [userId] = useContext(UserIdContext);
	
	const handleImg = (type, img) => {
		setQuestion(prevQ => ({
			...prevQ,
			[type]: img
		}));
	};
	
	const handleSubmit = async () => {
		let hasCorrectAnswer = false;
		for (let i = 0; i < question.options.length; i++) {
			const o = question.options[i];
			if (!o.chatOptions || !o.chatOptions.length) {
				return;
			}
			
			if (o.correct) {
				hasCorrectAnswer = true;
			}
		}
		
		if (!hasCorrectAnswer) {
			return setError('Please select correct answer');
		}
		
		setError(() => null);
		props.onSubmit({
			...question,
			mediaQuestion: mediaToJson(question.mediaQuestion),
			mediaAnswer: mediaToJson(question.mediaAnswer)
		});
	}
	
	const handleTextChange = text => {
		setQuestion(prevQ => ({
			...prevQ,
			text: text
		}));
	};
	
	const handleOptionChange = (index, text, isCorrect, chatOptions) => {
		setQuestion(prevQ => {
			const options = [...question.options];
			if (isCorrect) {
				options.forEach(o => o.correct = false);
			}
			options[index] = {text: text, correct: isCorrect, chatOptions: chatOptions};
			return {
				...prevQ,
				options: options
			}
		});
	};
	
	return (
		<>
			{error && <Notification severity={'error'} onClear={() => setError(null)} message={error}/>}
			
			<Dialog open={true} onClose={() => props.onCancel()} aria-labelledby='form-dialog-title' fullWidth={true} maxWidth={'lg'} className={'quiz-question-form'}>
				<ValidatorForm noValidate autoComplete='off' onSubmit={handleSubmit}>
					
					<DialogTitle id='form-dialog-title'>{props.title}</DialogTitle>
					<DialogContent dividers>
						
						<Grid item container direction={'row'} wrap={'nowrap'} alignItems={'flex-start'}>
							<Grid item className={'form-box'}>
								
								<SettingsFormControl>
									<TextValidator
										fullWidth
										required
										variant='outlined'
										label='Question'
										validators={['required']}
										errorMessages={['Question is required']}
										value={question.text}
										onChange={e => handleTextChange(e.target.value)}
									/>
								</SettingsFormControl>
								
								<Grid className={'quiz-question-form__options'}>
									{question.options.map((o, index) => (
										<Grid item className={'quiz-question-form__answer-option'} key={`ao${index}`}>
											<AnswerOption
												index={index + 1}
												text={o.text}
												correct={o.correct}
												chatOptions={o.chatOptions}
												onChange={(text, isCorrect, chatOptions) => handleOptionChange(index, text, isCorrect, chatOptions)}
											/>
										</Grid>
									))}
								</Grid>
							</Grid>
							
							{question.mediaQuestion && <Grid item xs={3} className={'img-box'}>
								<Typography>Question Media</Typography>
								<MediaPicker
									userId={userId}
									title={'Select Question Media'}
									media={question.mediaQuestion}
									onSelect={media => handleImg('mediaQuestion', media)}
								/>
							</Grid>}
							
							{question.mediaAnswer && <Grid item xs={3} className={'img-box'}>
								<Typography>Answer Media</Typography>
								<MediaPicker
									userId={userId}
									title={'Select Answer Media'}
									media={question.mediaAnswer}
									onSelect={media => handleImg('mediaAnswer', media)}
								/>
							</Grid>}
						</Grid>
					</DialogContent>
					
					<DialogActions className={'actions'}>
						{!question.mediaQuestion && <MediaPicker
							userId={userId}
							title={'Select Question Media'}
							media={question.mediaQuestion}
							onSelect={media => handleImg('mediaQuestion', media)}
						/>
						}
						
						{!question.mediaAnswer && <MediaPicker
							userId={userId}
							title={'Select Answer Media'}
							media={question.mediaAnswer}
							onSelect={media => handleImg('mediaAnswer', media)}
						/>
						}
						
						<LoadingButton className={'save-btn'} variant={'contained'} type={'submit'} loading={props.saving}>{props.btnTitle}</LoadingButton>
						<Button color={'secondary'} variant={'contained'} onClick={() => props.onCancel()}>Cancel</Button>
					</DialogActions>
				</ValidatorForm>
			</Dialog>
		</>
	);
}

QuestionForm.propTypes = {
	question: PropTypes.object.isRequired,
	onCancel: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	btnTitle: PropTypes.string,
	saving: PropTypes.bool,
	title: PropTypes.string.isRequired
}

QuestionForm.defaultProps = {
	btnTitle: 'Save',
	saving: false
}