import React, {useState} from 'react';
import StopIcon from '@mui/icons-material/Stop';
import IconButton from '@mui/material/IconButton';
import {CircularProgress, Tooltip} from '@mui/material';
import api from '../../common/ApiRequest';
import PropTypes from 'prop-types';

export default function StopButton(props) {
	
	const [loading, setLoading] = useState(false);
	const stopApp = () => {
		setLoading(true);
		api
			.post(`/apps/poll/${props.appId}/stop`)
			.catch(e => props.onError && props.onError(e))
			.finally(() => {
				setLoading(false);
				props.onChange && props.onChange();
			});
	};
	
	return <>
		{loading
			? <CircularProgress
				color='secondary'
				size={27}
				style={{display: 'flex', alignItems: 'center', margin: '0 16px'}}
			/>
			: <Tooltip title={props.title}>
				<IconButton aria-label='start' color='secondary' onClick={stopApp} size='large'>
					<StopIcon fontSize={'large'}/>
				</IconButton>
			</Tooltip>
		}
	</>;
}

StopButton.propTypes = {
	title: PropTypes.string,
	onChange: PropTypes.func,
	onError: PropTypes.func
}

StopButton.defaultProps = {
	title: 'Stop'
}