import React from 'react';
import {Button, Box, useTheme} from '@mui/material';
import OptionForm from './OptionForm';
import PropTypes from 'prop-types';

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';

export default function OptionsChat(props) {
	const theme = useTheme();
	const options = [...props.options];
	
	/**
	 * Add new empty option.
	 */
	const addNew = () => {
		options.push({
			text: '',
			chatOptions: [],
			seriesColor: '#fff',
			textColor: '#000',
		});
		props.onChange(options);
	}
	
	/**
	 * @param index
	 */
	const moveDown = index => {
		if (index >= options.length - 1) {
			return;
		}
		swap(index, index + 1);
	}
	
	/**
	 * @param index
	 */
	const moveUp = index => {
		if (index === 0) {
			return;
		}
		swap(index, index - 1);
	}
	
	/**
	 * Option form has been changed.
	 *
	 * @param model
	 * @param index
	 */
	const onChange = (model, index) => {
		options[index] = {...model};
		props.onChange(options);
	}
	
	/**
	 * @param index
	 */
	const onRemove = index => {
		options.splice(index, 1);
		props.onChange(options);
	}
	
	/**
	 * @param index1
	 * @param index2
	 */
	const swap = (index1, index2) => {
		const o1 = options[index1];
		options[index1] = options[index2];
		options[index2] = o1;
		props.onChange(options);
	}
	
	return (
		<Box css={css`
			display: flex;
			flex-direction: column;
			align-items: center;

			.list-item {
				padding-top: 0;
				padding-bottom: ${theme.spacing(2)};
			}`}>
			
			{options.map((o, i) => <div className={`option-list-item list-item`} key={`li${i}`}>
					<OptionForm
						{...o}
						canMoveDown={i < options.length - 1}
						canMoveUp={i > 0}
						onChange={o => onChange(o, i)}
						onMoveDown={() => moveDown(i)}
						onMoveUp={() => moveUp(i)}
						onRemove={() => onRemove(i)}
					/>
				</div>
			)}
			
			<Button
				variant={'contained'}
				color={'primary'}
				onClick={addNew}
			>
				Add Option
			</Button>
		</Box>
	)
}

OptionsChat.propTypes = {
	onChange: PropTypes.func.isRequired,
	options: PropTypes.array.isRequired
}