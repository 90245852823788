import React from 'react';
import PropTypes from 'prop-types';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import IconButton from '@mui/material/IconButton';
import {Tooltip} from '@mui/material';

export default function ActivityLogButton(props) {
	return (
		<Tooltip title={'Toggle Activity Log'}>
			<IconButton size={'large'} onClick={props.onClick} disabled={props.disabled}>
				<MonitorHeartIcon fontSize={'large'} color={props.active ? 'primary' : ''}/>
			</IconButton>
		</Tooltip>
	)
}

ActivityLogButton.propTypes = {
	active: PropTypes.bool,
	disabled: PropTypes.bool,
	onClick: PropTypes.func
}

ActivityLogButton.defaultProps = {
	active: true,
	disabled: false
}