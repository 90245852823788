import React from 'react';
import QuizEditor from './components/QuizEditor';
import api from '../common/ApiRequest';
import PagePreloader from '../common/components/PagePreloader';
import Notification from '../common/components/Notification';
import WithChannels from '../common/providers/WithChannels';
import UserIdContext from '../common/UserIdContext';
import {sanitize} from './services/QuizService';
import withLocation from '../common/providers/withLocation';

class QuizEdit extends React.Component {
	
	constructor(props, context) {
		super(props, context);
		
		const query = new URLSearchParams(this.props.location.search);
		let message = null;
		if (query.get('created')) {
			message = 'Quiz has been created';
		} else if (query.get('duplicated')) {
			message = 'Quiz has been duplicated';
		}
		
		this.state = {
			loadingError: null,
			saving: false,
			message: message,
			severity: 'success',
			model: null
		};
	}
	
	async componentDidMount() {
		try {
			const quiz = api.get('/apps/quiz/' + this.props.params.quizId);
			this.setState({model: await quiz});
		} catch (e) {
			this.setState({loadingError: e.toString()});
		}
	}
	
	onSubmit(model) {
		this.setState({
			saving: true
		});
		
		api.post(`/apps/quiz/${model._id}`, sanitize(model))
			.then(model => this.setState({
					model,
					message: 'Saved',
					severity: 'success',
					saving: false
				})
			)
			.catch(e => this.setState({
					message: e.toString(),
					severity: 'error',
					saving: false
				})
			);
	}
	
	render() {
		if (this.state.loadingError || !this.state.model) {
			return <PagePreloader error={this.state.loadingError}/>
		}
		
		return (
			<UserIdContext.Provider value={[this.state.model.user]}>
				<Notification
					message={this.state.message}
					severity={this.state.severity}
					onClear={() => this.setState({message: null})}
				/>
				<WithChannels userId={this.state.model.user}>
					<QuizEditor
						model={this.state.model}
						saving={this.state.saving}
						onSubmit={this.onSubmit.bind(this)}
						onError={error => this.setState({message: error.toString(), severity: 'error'})}
					/>
				</WithChannels>
			</UserIdContext.Provider>
		)
	}
}

export default withLocation(QuizEdit);