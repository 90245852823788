import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import {CircularProgress} from '@mui/material';
import PropTypes from 'prop-types';
import {Tooltip} from '@mui/material';

export default function AjaxButton(props) {
	
	const [loading, setLoading] = useState(false);
	const Icon = props.icon;
	
	const onClick = () => {
		setLoading(true);
		props.exec
			.call()
			.then(data => props.onChange && props.onChange(data))
			.catch(e => props.onError && props.onError(e))
			.finally(() => {
				setTimeout(() => setLoading(false), 500);
			});
	};
	
	return <div className={props.className}>
		{loading
			? <CircularProgress
				color={props.progressColor}
				size={props.progressSize}
				style={{display: 'flex', alignItems: 'center', margin: `0 ${props.progressMargin}`}}
			/>
			: <Tooltip title={props.title}>
                <span>
                    <IconButton
	                    aria-label={props.ariaLabel}
	                    color={props.color}
	                    disabled={props.disabled}
	                    onClick={onClick}
	                    size={props.size}>
                        <Icon fontSize={props.size}/>
                    </IconButton>
                </span>
			</Tooltip>
		}
	</div>;
}

AjaxButton.propTypes = {
	ariaLabel: PropTypes.string,
	className: PropTypes.string,
	color: PropTypes.string,
	disabled: PropTypes.bool,
	exec: PropTypes.func.isRequired,
	icon: PropTypes.object.isRequired,
	onChange: PropTypes.func,
	onError: PropTypes.func,
	progressColor: PropTypes.string,
	progressSize: PropTypes.number,
	size: PropTypes.string,
	title: PropTypes.string,
}

AjaxButton.defaultProps = {
	ariaLabel: '',
	className: '',
	color: 'primary',
	disabled: false,
	progressColor: 'secondary',
	progressMargin: '16px',
	progressSize: 27,
	size: 'large'
}