import objectPath from 'object-path';
import auth from '../../auth/AuthService';

const dummyUsers = [
	{
		displayName: 'Will Smith',
		profileImageUrl: '/images/people/will-smith.webp'
	},
	{
		displayName: 'Arnold Schwarzenegger',
		profileImageUrl: '/images/people/arnold-schwarzenegger.webp'
	},
	{
		displayName: 'Brad Pitt',
		profileImageUrl: '/images/people/brad-pitt.webp'
	},
	{
		displayName: 'Charlize Theron',
		profileImageUrl: '/images/people/charlize-theron.webp'
	},
	{
		displayName: 'Emilia Clarke',
		profileImageUrl: '/images/people/emilia-clarke.webp'
	},
	{
		displayName: 'Mila Kunis',
		profileImageUrl: '/images/people/mila-kunis.webp'
	},
	{
		displayName: 'Milla Jovovich',
		profileImageUrl: '/images/people/milla-jovovich.webp'
	},
	{
		displayName: 'George Clooney',
		profileImageUrl: '/images/people/george-clooney.webp'
	},
	{
		displayName: 'Keanu Reeves',
		profileImageUrl: '/images/people/keanu-reeves.webp'
	},
	{
		displayName: 'Bruce Willis',
		profileImageUrl: '/images/people/bruce-willis.webp'
	}
];

export function createEmptyQuiz() {
	return {
		name: '',
		user: auth.getCurrentUser()._id,
		livestreams: [],
		questionTime: 15,
		answerTime: 10,
		chatDelay: 5,
		questionOrder: 'strict',
		loopQuestions: false,
		questionsPerRound: 0,
		maxScore: 10,
		minScore: 1,
		penaltyScore: 0,
		scoreStep: 1,
		defaultScoreScreen: 'user',
		screens: {
			welcome: {
				enabled: true,
				duration: 60,
				countdown: {
					enabled: true,
					position: 'bottom',
					fontSize: 65,
					color: '#fff',
					bgColor: 'rgba(0, 0, 0, 0.7)'
				},
				bgColor: '#fff',
				background: {
					mediaType: 'image',
					image: {
						'_id': '61a6abc612860637006dd7b3',
						'src': 'https://s3.amazonaws.com/streamsmart.app/defaults/starting-soon.webp',
						'thumbSrc': 'https://s3.amazonaws.com/streamsmart.app/defaults/starting-soon-thumb.webp'
					}
				}
			},
			question: {
				bgColor: '#fff',
				background: {
					mediaType: 'image',
					image: {
						'_id': '61a6abc612860637006dd7b0',
						'src': 'https://s3.amazonaws.com/streamsmart.app/defaults/orange-bg.webp',
						'thumbSrc': 'https://s3.amazonaws.com/streamsmart.app/defaults/orange-bg-thumb.webp'
					}
				},
				displayProgress: false,
				question: {
					fontSize: 50,
					color: '#fff',
					bgColor: 'rgba(4, 153, 255, 0.9)',
				},
				option: {
					fontSize: 40,
					color: '#fff',
					bgColor: 'rgba(205, 60, 232, 0.9)',
				},
				countdown: {
					vPosition: 'center',
					hPosition: 'center',
					fontSize: 50,
					color: '#fff',
					bgColor: 'rgba(0, 0, 0, 0.6)',
					borderSize: 5,
					borderColor: '#000',
					sound: ''
				}
			},
			answer: {
				color: '#fff',
				textBgColor: 'rgba(55, 183, 35, 0.9)',
				bgColor: '#fff',
				background: {
					mediaType: 'image',
					image: {
						'_id': '61a6abc612860637006dd7b0',
						'src': 'https://s3.amazonaws.com/streamsmart.app/defaults/orange-bg.webp',
						'thumbSrc': 'https://s3.amazonaws.com/streamsmart.app/defaults/orange-bg-thumb.webp'
					}
				},
				sound: ''
			},
			scores: {
				title: 'Scores',
				failText: '😫 No one answered correctly 😩',
				duration: 10,
				screenTitle: {
					fontSize: 45,
					color: '#fff',
					bgColor: 'rgba(147, 84, 235, 0.9)'
				},
				success: {
					showPos: false,
					autoScroll: true,
					scrollDelay: 5,
					fontSize: 24,
					textColor: '#fff',
					scoreColor: '#42f703',
					penaltyScoreColor: '#f00',
					totalScoresColor: '#f6f846',
					rowBgColor: 'rgba(40, 40, 40, 0.7)',
					bgColor: '#464646',
					background: {
						mediaType: 'image',
						image: {
							'_id': '61a6abc612860637006dd7ae',
							'src': 'https://s3.amazonaws.com/streamsmart.app/defaults/scores-sucess.webp',
							'thumbSrc': 'https://s3.amazonaws.com/streamsmart.app/defaults/scores-sucess-thumb.webp'
						}
					},
					sound: ''
				},
				fail: {
					position: 'bottom',
					fontSize: 50,
					color: '#f71010',
					textBgColor: 'rgba(40, 40, 40, 0.8)',
					bgColor: '#fff',
					background: {
						mediaType: 'image',
						image: {
							'_id': '61a6abc612860637006dd7b1',
							'src': 'https://s3.amazonaws.com/streamsmart.app/defaults/cat-bg.webp',
							'thumbSrc': 'https://s3.amazonaws.com/streamsmart.app/defaults/cat-bg-thumb.webp'
						}
					},
					sound: ''
				}
			},
			final: {
				screenType: 'scores',
				duration: 60,
				background: {
					mediaType: 'image',
					image: {
						'_id': '61a6abc612860637006dd7b2',
						'src': 'https://s3.amazonaws.com/streamsmart.app/defaults/thank-you.webp',
						'thumbSrc': 'https://s3.amazonaws.com/streamsmart.app/defaults/thank-you-thumb.webp'
					}
				},
				scoresStyles: {
					title: {
						text: 'Results',
						fontSize: 45,
						color: '#fff',
						bgColor: 'rgba(147, 84, 235, 0.9)'
					},
					showPos: true,
					autoScroll: true,
					scrollDelay: 5,
					fontSize: 24,
					textColor: '#fff',
					totalScoresColor: '#f6f846',
					rowBgColor: 'rgba(40, 40, 40, 0.7)',
					bgColor: '#464646',
					background: {
						mediaType: 'image',
						image: {
							'_id': '61a6abc612860637006dd7ae',
							'src': 'https://s3.amazonaws.com/streamsmart.app/defaults/scores-sucess.webp',
							'thumbSrc': 'https://s3.amazonaws.com/streamsmart.app/defaults/scores-sucess-thumb.webp'
						}
					}
				},
				sound: ''
			},
		},
		questions: [],
		teams: [],
		inheritTeamsFrom: null,
		customCss: '',
		poster: null
	}
}

export function getDummyScores(maxScore, scoreStep, minScore, penaltyScore, teams) {
	
	maxScore = maxScore || 10;
	minScore = minScore || 1;
	scoreStep = scoreStep || 1;
	
	let totalStep = maxScore;
	let totalScores = dummyUsers.length * totalStep;
	
	const result = [];
	const numCorrect = Math.floor(Math.random() * dummyUsers.length);
	
	for (let i = 0; i < dummyUsers.length; i++) {
		const row = {
			user: dummyUsers[i],
			scores: i < numCorrect ? Math.max(minScore, maxScore - i * scoreStep) : penaltyScore,
			totalScores: Math.max(minScore, Math.floor(Math.random() * totalScores))
		};
		
		if (teams && teams.length) {
			const index = Math.floor(Math.random() * teams.length);
			row.team = JSON.parse(JSON.stringify(teams[index]));
		}
		
		result.push(row);
	}
	
	return result;
}

export function getDummyTeamScores(maxScore, scoreStep, minScore, penaltyScore, teams) {
	const result = {};
	if (!teams || !teams.length) {
		return result;
	}
	
	const userScores = getDummyScores(maxScore, scoreStep, minScore, penaltyScore, teams);
	userScores.forEach(score => {
		const teamId = score.team._id;
		result[teamId] = result[teamId] || {
			users: 0,
			correctAnswers: 0,
			totalAnswers: 0,
			totalScores: 0
		};
		const scoresRow = result[teamId];
		scoresRow.users++;
		scoresRow.totalAnswers++;
		
		if (score.scores) {
			scoresRow.correctAnswers++;
			scoresRow.totalScores += score.scores;
		}
	});
	
	return result;
}

export function sanitize(data) {
	const newData = JSON.parse(JSON.stringify(data));
	
	delete newData.scores;
	delete newData.leaderboardUrl;
	delete newData.teamsLeaderboardUrl;
	delete newData.serverUrl;
	
	// Sanitize images.
	const imagePaths = ['poster'];
	imagePaths.forEach(path => sanitizeImage(newData, path));
	
	// Sanitize teams.
	sanitizeTeams(newData);
	if (newData.inheritTeamsFrom) {
		newData.inheritTeamsFrom = newData.inheritTeamsFrom._id;
	}
	
	// Sanitize media.
	const mediaPaths = [
		'screens.question.background',
		'screens.answer.background',
		'screens.final.background',
		'screens.final.scoresStyles.background',
		'screens.scores.success.background',
		'screens.scores.fail.background',
		'screens.welcome.background'
	];
	mediaPaths.forEach(path => sanitizeMedia(newData, path));
	
	// Sanitize livestreams.
	if (newData.livestreams) {
		newData.livestreams = newData.livestreams.map(livestream => {
			if (typeof livestream.channel === 'object') {
				livestream.channel = livestream.channel._id;
			}
			return livestream;
		});
	}
	
	// Poll
	newData.poll = newData.poll || null;
	
	return newData;
}

function sanitizeImage(obj, path) {
	const img = objectPath.get(obj, path);
	if (img && img._id) {
		objectPath.set(obj, path, img._id);
	} else {
		objectPath.set(obj, path, null);
	}
}

function sanitizeMedia(obj, path) {
	const media = objectPath.get(obj, path);
	if (media && media.mediaType === 'image' && media.image && media.image._id) {
		objectPath.set(obj, path, {mediaType: 'image', image: media.image._id});
	} else if (media && media.mediaType === 'video' && media.video && media.video._id) {
		objectPath.set(obj, path, {mediaType: 'video', video: media.video._id});
	} else {
		return objectPath.set(obj, path, null);
	}
}

function sanitizeTeams(obj) {
	if (obj.teams && obj.teams.length > 0) {
		obj.teams = obj.teams.map(t => ({
			_id: t._id,
			name: t.name,
			color: t.color,
			chatOptions: t.chatOptions,
			img: t.img ? t.img._id : null
		}));
	} else {
		obj.teams = null;
	}
}
