import React from 'react';
import {FormControl, useTheme} from '@mui/material';
import PropTypes from 'prop-types';

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';

export default function SettingsFormControl(props) {
	const theme = useTheme();
	return <FormControl
		css={css`margin-bottom: ${theme.spacing(3)}; display: flex`}
		fullWidth
		{...props}
		className={`${props.className} settings-form-control`}>{props.children}</FormControl>
}

SettingsFormControl.propTypes = {
	className: PropTypes.string
}

SettingsFormControl.defaultProps = {
	className: ''
}