import {InputAdornment, Paper, TextField} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import React, {useState} from 'react';
import PropTypes from 'prop-types';

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';

export default function SearchBar(props) {
	const [key, setKey] = useState(null);
	
	const onClear = () => {
		setKey('');
		props.onSubmit && props.onSubmit('');
	}
	
	const onKeyPress = e => {
		if (e.key === 'Enter' || e.which === 13) {
			props.onSubmit && props.onSubmit(key);
		}
	}
	
	return (
		<Paper css={css`
			display: flex;
			align-items: center;
			padding: 8px;
			& .search-field {
				width: 100%;
			}
		`}>
			<TextField
				value={key || ''}
				size={'small'}
				className={'search-field'}
				placeholder={props.placeholder}
				onChange={e => setKey(e.target.value || '')}
				onKeyPress={onKeyPress}
				InputProps={{
					endAdornment: <InputAdornment position={'end'}>
						<IconButton onClick={onClear} edge={'end'}>
							<HighlightOffIcon/>
						</IconButton>
					</InputAdornment>
				}}
			/>
		</Paper>
	);
}

SearchBar.propTypes = {
	onSubmit: PropTypes.func,
	placeholder: PropTypes.string
}

SearchBar.defaultProps = {
	placeholder: 'Search...'
}