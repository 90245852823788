import React from 'react';
import SettingsBox from '../../common/components/settings/SettingsBox';
import SettingsFormControl from '../../common/components/settings/SettingsFormControl';
import ImagePicker from '../../common/components/media/ImagePicker';
import PropTypes from 'prop-types';

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';

export default function PosterBox(props) {
	const model = props.model;
	
	return (
		<SettingsBox title={'Poster'}>
			<SettingsFormControl>
				<ImagePicker
					userId={model.user}
					img={model.poster}
					onSelect={img => props.onChange('poster', img)}
					css={css`display: block`}
				/>
			</SettingsFormControl>
		</SettingsBox>
	)
}

PosterBox.propTypes = {
	model: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired
}