import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {Box, Typography} from '@mui/material';
import SettingsFormControl from '../../../common/components/settings/SettingsFormControl';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {ValidatorForm} from 'react-material-ui-form-validator';
import QuestionScreen from '../../../common-libs/quiz/screens/QuestionScreen';
import SizePicker from '../../../common/components/settings/SizePicker';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MediaPicker from '../../../common/components/media/MediaPicker';
import AppColorPicker from '../../../common/components/settings/AppColorPicker';
import SoundPicker from '../../../common/components/settings/SoundPicker';
import Scale from '../../../common/components/Scale';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import QuestionPicker from '../../components/QuestionPicker';

const sounds = {
	'cartoon-clock': 'Cartoon Clock',
	'clock-ticking': 'Clock Ticking',
	'fast-tick': 'Fast Tick',
	'kitchen-timer': 'Kitchen Timer',
	'slow-tick': 'Slow Tick',
	'tick-tok': 'Tick Tok',
	'timer': 'Timer',
	'wall-clock': 'Wall Clock'
};

const defaultPreview = {
	_id: 'demo',
	text: 'Question',
	position: 1,
	options: [{text: 'Option 1', correct: true}, {text: 'Option 2'}, {text: 'Option 3'}, {text: 'Option 4'}]
};

export default function QuestionStyleTab(props) {
	const model = props.model;
	const [currentQuestion, setCurrentQuestion] = useState(null);
	const [firstTabOpen, setFirstTabOpen] = useState(true);
	const previewData = {
		...model,
		status: 'running',
		numQuestionsAsked: currentQuestion ? currentQuestion.position : 0,
		totalQuestions: model.numQuestions || 1
	};
	
	return (
		<Grid container className={'preview-style-tab question-style-tab'} spacing={2}>
			<Grid item xs={3}>
				<ValidatorForm noValidate autoComplete='off' onSubmit={props.onSubmit}>
					
					<Accordion expanded={firstTabOpen} onChange={() => setFirstTabOpen(!firstTabOpen)}>
						<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
							<Typography className={'typography'}>Question</Typography>
						</AccordionSummary>
						
						<AccordionDetails className={'accordion-content'}>
							<SizePicker
								value={model.screens.question.question.fontSize}
								min={30}
								max={100}
								onChange={v => props.onChange('screens.question.question.fontSize', v)}
							/>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Text Color'
									value={model.screens.question.question.color}
									TextFieldProps={{value: model.screens.question.question.color}}
									onChange={value => value && props.onChange('screens.question.question.color', value)}
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Background Color'
									value={model.screens.question.question.bgColor}
									TextFieldProps={{value: model.screens.question.question.bgColor}}
									onChange={value => value && props.onChange('screens.question.question.bgColor', value)}
								/>
							</SettingsFormControl>
							
							<Box style={{width: '100%'}}>
								<FormControlLabel
									control={<Switch
										color='primary'
										checked={model.screens.question.displayProgress}
										onChange={e => props.onChange('screens.question.displayProgress', e.target.checked)}
									/>}
									label='Display Progress'
									labelPlacement='end'
								/>
							</Box>
						</AccordionDetails>
					</Accordion>
					
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
							<Typography className={'typography'}>Answer Options</Typography>
						</AccordionSummary>
						<AccordionDetails className={'accordion-content'}>
							<SizePicker
								value={model.screens.question.option.fontSize}
								min={25}
								max={90}
								onChange={v => props.onChange('screens.question.option.fontSize', v)}
							/>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Text Color'
									value={model.screens.question.option.color}
									TextFieldProps={{value: model.screens.question.option.color}}
									onChange={value => value && props.onChange('screens.question.option.color', value)}
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Background Color'
									value={model.screens.question.option.bgColor}
									TextFieldProps={{value: model.screens.question.option.bgColor}}
									onChange={value => value && props.onChange('screens.question.option.bgColor', value)}
								/>
							</SettingsFormControl>
						</AccordionDetails>
					</Accordion>
					
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
							<Typography className={'typography'}>Count Down</Typography>
						</AccordionSummary>
						<AccordionDetails className={'accordion-content'}>
							<SettingsFormControl variant={'outlined'}>
								<InputLabel style={{backgroundColor: '#fff'}}>Vertical Position</InputLabel>
								<Select
									value={model.screens.question.countdown.vPosition}
									onChange={e => props.onChange('screens.question.countdown.vPosition', e.target.value)}
								>
									<MenuItem value={'top'} key={'vt'}>Top</MenuItem>
									<MenuItem value={'center'} key={'vc'}>Center</MenuItem>
									<MenuItem value={'bottom'} key={'vb'}>Bottom</MenuItem>
								</Select>
							</SettingsFormControl>
							
							<SettingsFormControl variant={'outlined'}>
								<InputLabel style={{backgroundColor: '#fff'}}>Horizontal Position</InputLabel>
								<Select
									value={model.screens.question.countdown.hPosition}
									onChange={e => props.onChange('screens.question.countdown.hPosition', e.target.value)}
								>
									<MenuItem value={'left'} key={'hl'}>Left</MenuItem>
									<MenuItem value={'center'} key={'hc'}>Center</MenuItem>
									<MenuItem value={'right'} key={'hr'}>Right</MenuItem>
								</Select>
							</SettingsFormControl>
							
							<SizePicker
								value={model.screens.question.countdown.fontSize}
								min={30}
								max={100}
								onChange={v => props.onChange('screens.question.countdown.fontSize', v)}
							/>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Text Color'
									value={model.screens.question.countdown.color}
									TextFieldProps={{value: model.screens.question.countdown.color}}
									onChange={value => value && props.onChange('screens.question.countdown.color', value)}
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Background Color'
									value={model.screens.question.countdown.bgColor}
									TextFieldProps={{value: model.screens.question.countdown.bgColor}}
									onChange={value => value && props.onChange('screens.question.countdown.bgColor', value)}
								/>
							</SettingsFormControl>
							
							<SizePicker
								label='Border Size'
								value={model.screens.question.countdown.borderSize}
								min={0}
								max={50}
								onChange={v => props.onChange('screens.question.countdown.borderSize', v)}
							/>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Border Color'
									value={model.screens.question.countdown.borderColor}
									TextFieldProps={{value: model.screens.question.countdown.borderColor}}
									onChange={value => value && props.onChange('screens.question.countdown.borderColor', value)}
								/>
							</SettingsFormControl>
							
							<SettingsFormControl variant={'outlined'}>
								<SoundPicker
									sounds={sounds}
									value={model.screens.question.countdown.sound}
									onChange={value => props.onChange('screens.question.countdown.sound', value)}
								/>
							</SettingsFormControl>
						
						</AccordionDetails>
					</Accordion>
					
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
							<Typography className={'typography'}>Background</Typography>
						</AccordionSummary>
						<AccordionDetails className={'accordion-content'}>
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Background Color'
									value={model.screens.question.bgColor}
									TextFieldProps={{value: model.screens.question.bgColor}}
									onChange={value => value && props.onChange('screens.question.bgColor', value)}
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<MediaPicker
									userId={model.user}
									title={'Select Background'}
									media={model.screens.question.background}
									onSelect={media => props.onChange('screens.question.background', media)}
								/>
							</SettingsFormControl>
						</AccordionDetails>
					</Accordion>
					
					<Paper className={'save-box'}>
						<Button
							variant={'contained'}
							color={'primary'}
							disabled={props.saving}
							type={'submit'}
						>
							Save Changes
						</Button>
					</Paper>
				</ValidatorForm>
			</Grid>
			
			<Grid item xs={9}>
				<Paper className={'question-select'}>
					<SettingsFormControl variant={'outlined'}>
						<QuestionPicker
							quizId={model._id}
							label={'Preview Question'}
							onChange={q => q ? setCurrentQuestion(q) : setCurrentQuestion(defaultPreview)}
						/>
					</SettingsFormControl>
				</Paper>
				<Scale selector={'.quiz-screen'} className={'preview game-page'}>
					{currentQuestion && <QuestionScreen
						key={`preview-${currentQuestion._id}`}
						quiz={previewData}
						question={currentQuestion}
						timeLeft={model.questionTime * 1000}
					/>}
				</Scale>
			</Grid>
		</Grid>
	)
}