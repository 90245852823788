import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {Box} from '@mui/material';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';

export default function PollTabs(props) {
	const tabs = [
		{label: 'Settings', path: ''},
		{label: 'Questions', path: 'questions'},
		{label: 'Custom Styles', path: 'custom-css'},
		{label: <Box className={'tab-with-icon'}>How to stream <HelpOutlineIcon className={'tab-icon'} fontSize={'small'}/></Box>, path: 'how-to-stream'},
		{label: <Box className={'tab-with-icon'}>Control Room <SettingsRemoteIcon className={'tab-icon'} fontSize={'small'} color={'secondary'}/></Box>, path: 'control-room'}
	];
	
	let filteredTabs = tabs;
	let selectedIndex = 0;
	if (props.mode === 'add') {
		filteredTabs = [tabs[0]];
	} else if (props.selected) {
		selectedIndex = tabs.findIndex(t => t.path === props.selected);
		if (selectedIndex === -1) {
			selectedIndex = 0;
		}
	}
	
	const tabsEl = filteredTabs.map((t, i) => <Tab
		label={t.label}
		key={`tab-${i}`}
		id={`tab-${i}`}
		aria-controls={`tabpanel-${i}`}
		onClick={() => props.onClick && props.onClick(t.path)}
	/>);
	
	return <Tabs centered value={selectedIndex} aria-label='Poll Editor Sections' className={'poll-nav-tabs'}>{tabsEl}</Tabs>
}

PollTabs.propTypes = {
	mode: PropTypes.string,
	onClick: PropTypes.func,
	selected: PropTypes.string
};

PollTabs.defaultProps = {
	mode: 'add'
};