import SettingsFormControl from '../../common/components/settings/SettingsFormControl';
import {Box, IconButton, Paper} from '@mui/material';
import {TextValidator} from 'react-material-ui-form-validator';
import AppColorPicker from '../../common/components/settings/AppColorPicker';
import React from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import ChipInput from '../../common/components/settings/ChipInput';

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';

export default function OptionForm(props) {
	/**
	 * @param key
	 * @param val
	 */
	const onChange = (key, val) => {
		const model = {
			text: props.text,
			chatOptions: props.chatOptions,
			seriesColor: props.seriesColor,
			textColor: props.textColor
		};
		model[key] = val;
		props.onChange(model);
	}
	
	return (
		<Paper css={css`
			padding: 16px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		
			.form-control {
				padding-right: 8px;
			}
			.options {
				width: 100%;
			}`}>
			
			<SettingsFormControl className={'form-control'}>
				<TextValidator
					label={'Option'}
					value={props.text}
					size='small'
					fullWidth
					required
					validators={['required']}
					errorMessages={['Chat command is required']}
					onChange={e => onChange('text', e.target.value)}
				/>
			</SettingsFormControl>
			
			<Box mb={2} className={'options'}>
				<ChipInput
					label={'Chat options'}
					required={true}
					inputProps={{variant: 'standard'}}
					errorMsg={!props.chatOptions.length ? 'Chat options are required' : null}
					onChange={values => onChange('chatOptions', values)}
					options={props.chatOptions}
				/>
			</Box>
			
			<SettingsFormControl className={'form-control'}>
				<AppColorPicker
					variant={'standard'}
					label='Color'
					value={props.seriesColor}
					TextFieldProps={{value: props.seriesColor}}
					onChange={v => onChange('seriesColor', v)}
				/>
			</SettingsFormControl>
			
			<SettingsFormControl className={'form-control'}>
				<AppColorPicker
					variant={'standard'}
					label='Text Color'
					value={props.textColor}
					TextFieldProps={{value: props.textColor}}
					onChange={v => onChange('textColor', v)}
				/>
			</SettingsFormControl>
			
			<Box>
				<IconButton
					aria-label='move up'
					disabled={!props.canMoveUp}
					onClick={() => props.onMoveUp()}
					size={'small'}
					title={'Move Up'}
				>
					<ArrowUpwardIcon fontSize={'inherit'}/>
				</IconButton>
				
				<IconButton
					aria-label='move down'
					disabled={!props.canMoveDown}
					onClick={() => props.onMoveDown()}
					size={'small'}
					title={'Move Down'}
				>
					<ArrowDownwardIcon fontSize={'inherit'}/>
				</IconButton>
				
				<IconButton
					aria-label='delete'
					color='secondary'
					onClick={() => props.onRemove()}
					size={'small'}
					title={'Remove'}
				>
					<DeleteIcon fontSize={'inherit'}/>
				</IconButton>
			</Box>
		</Paper>
	)
}

OptionForm.propTypes = {
	canMoveDown: PropTypes.bool,
	canMoveUp: PropTypes.bool,
	chatOptions: PropTypes.array,
	color: PropTypes.string,
	label: PropTypes.string.isRequired,
	onMoveDown: PropTypes.func,
	onMoveUp: PropTypes.func,
	onRemove: PropTypes.func,
	title: PropTypes.string
}

OptionForm.defaultProps = {
	canMoveDown: true,
	canMoveUp: true,
	chatOptions: [],
	color: '#fff',
	label: 'Option',
	title: ''
};
