import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import api from '../../../ApiRequest';

export default function LiveStreamPicker(props) {
	
	const [ajaxComplete, setAjaxComplete] = React.useState(false);
	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState([]);
	
	let channelId = '';
	if (props.value && props.value.channel) {
		channelId = typeof props.value.channel === 'object' ? props.value.channel._id : props.value.channel;
	}
	const loading = open && !ajaxComplete;
	
	React.useEffect(() => {
		let active = true;
		
		if (!loading) {
			return;
		}
		
		(async () => {
			try {
				const streams = await api.get('/youtube/livestreams/' + channelId);
				if (active) {
					setOptions(streams);
					setAjaxComplete(() => true);
				}
			} catch (e) {
				if (active) {
					if (props.onError) {
						props.onError(e);
					}
					setOpen(false);
					setAjaxComplete(() => false);
				}
			}
		})();
		
		return () => {
			active = false;
		};
	}, [loading, channelId]);
	
	React.useEffect(() => {
		if (!open) {
			setOptions([]);
			setAjaxComplete(() => false);
		}
	}, [open]);
	
	return (
		<Autocomplete
			disabled={!channelId || props.disabled}
			open={open}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			onChange={(event, newValue) => {
				props.onChange(event, {...newValue, channel: channelId});
			}}
			value={props.value}
			isOptionEqualToValue={(option, value) => value && option && option.ytStreamId === value.ytStreamId}
			getOptionLabel={(option) => option.title || ''}
			options={options}
			loading={loading}
			renderInput={(params) => (
				<TextField
					{...params}
					label='Live Stream'
					variant='outlined'
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{loading ? <CircularProgress color='inherit' size={20}/> : null}
								{params.InputProps.endAdornment}
							</>
						),
					}}
				/>
			)}
		/>
	);
}
