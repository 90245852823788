import React, {useEffect, useState} from 'react';
import SettingsBox from '../SettingsBox';
import PropTypes from 'prop-types';
import {Button, ListItemIcon} from '@mui/material';
import SettingsFormControl from '../SettingsFormControl';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import LiveStreamPicker from './LiveStreamPicker';
import SocialIcon from '../../SocialIcon';

export default function LiveStreamsSettingsBox(props) {
	const [livestreams, setLivestreams] = useState(props.livestreams || []);
	
	const addLivestream = () => {
		setLivestreams(prev => [...prev, {}]);
	}
	
	const onChange = (index, livestream) => {
		setLivestreams(prev => {
			const newList = [...prev];
			newList[index] = {...livestream};
			return newList;
		});
	}
	
	const onDelete = index => {
		setLivestreams(prev => {
			const newList = [...prev];
			newList.splice(index, 1);
			return newList;
		});
	};
	
	useEffect(() => {
		props.onChange(livestreams);
	}, [livestreams]);
	
	return (
		<SettingsBox title={'Live Streams'}>
			{livestreams.map((livestream, i) => <LiveStreamLine
					key={`ls${i}`}
					channels={props.channels}
					disabled={props.disabled}
					livestream={livestream}
					onChange={livestream => onChange(i, livestream)}
					onDelete={() => onDelete(i)}
					onError={props.onError}
				/>
			)}
			<SettingsFormControl style={{alignItems: 'center'}}>
				<Button variant={'contained'} onClick={addLivestream} disabled={props.disabled}>Add Live Stream</Button>
			</SettingsFormControl>
		</SettingsBox>
	);
}

function LiveStreamLine(props) {
	const livestream = props.livestream;
	
	let channel = null;
	if (livestream) {
		if (typeof livestream.channel === 'object') {
			channel = livestream.channel;
		} else {
			channel = props.channels.find(c => c._id === livestream.channel);
		}
	}
	const showLivestreamDropdown = !channel || channel.socialType === 'youtube';
	
	return (
		<Grid container spacing={1} className={'livestream-settings-line'}>
			
			<Grid item xs={showLivestreamDropdown ? 6 : 11}>
				<SettingsFormControl variant={'outlined'}>
					<InputLabel required style={{backgroundColor: '#fff'}}>Channel</InputLabel>
					<Select
						required
						disabled={props.disabled}
						value={channel ? channel._id || '' : ''}
						onChange={e => props.onChange({channel: e.target.value})}
					>
						{props.channels.map(c => <MenuItem key={`c${c._id}`} value={c._id}>
							<ListItemIcon className={'social-type'}>
								<SocialIcon type={c.socialType}/>
							</ListItemIcon>
							{c.title}
						</MenuItem>)}
					</Select>
				</SettingsFormControl>
			</Grid>
			
			{showLivestreamDropdown && <Grid item xs={5}>
				<SettingsFormControl>
					<LiveStreamPicker
						value={livestream}
						disabled={props.disabled}
						onChange={(e, newValue) => props.onChange(newValue)}
						onError={props.onError}
					/>
				</SettingsFormControl>
			</Grid>}
			
			<Grid item xs={1}>
				<IconButton aria-label={'delete'} onClick={props.onDelete} disabled={props.disabled}>
					<DeleteIcon fontSize={'inherit'} color={props.disabled ? 'disabled' : 'error'}/>
				</IconButton>
			</Grid>
		
		</Grid>
	);
}

LiveStreamsSettingsBox.propTypes = {
	channels: PropTypes.array,
	disabled: PropTypes.bool,
	livestreams: PropTypes.array,
	onChange: PropTypes.func.isRequired,
	onError: PropTypes.func
};

LiveStreamsSettingsBox.defaultProps = {
	channels: [],
	disabled: false,
	livestreams: []
};
