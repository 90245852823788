import React from 'react';
import Container from '@mui/material/Container';
import PropTypes from 'prop-types';

export default function MainContainer(props) {
	return <Container className={props.className} style={{marginTop: '20px', padding: 0}} maxWidth={props.maxWidth}>
		{props.children}
	</Container>
}

MainContainer.propTypes = {
	className: PropTypes.string,
	maxWidth: PropTypes.string
}

MainContainer.defaultProps = {
	maxWidth: 'lg'
}