import React, {useEffect, useState} from 'react';
import {Avatar, Box, LinearProgress, TableCell} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import api from '../../common/ApiRequest';
import objectPath from 'object-path';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export default function ScoresPopup(props) {
	
	const app = props.app;
	const open = props.open;
	const [scores, setScores] = useState(null);
	const [loading, setLoading] = useState(false);
	
	/**
	 * Load scores on popup open.
	 */
	useEffect(() => {
		if (!open) {
			return;
		} else if (loading) {
			return;
		}
		setLoading(() => true);
		
		let active = true;
		(async () => {
			try {
				const quiz = await api.get(`/apps/quiz/${app._id}?scores=1`);
				if (active) {
					setScores(() => objectPath.get(quiz, 'leaderboard.topUsers', []));
				}
			} catch (e) {
				console.log(e);
			}
			setLoading(() => false);
		})();
		
		return () => {
			active = false;
		};
	}, [open]);
	
	return (
		<Dialog fullScreen open={open} onClose={() => props.onClose && props.onClose()} TransitionComponent={Transition} className={'quiz-scores-popup'}>
			<AppBar className={'bar'}>
				<Toolbar>
					<IconButton
						edge='start'
						color='inherit'
						onClick={() => props.onClose && props.onClose()}
						aria-label='close'
						size='large'>
						<CloseIcon/>
					</IconButton>
					<Typography variant='h6' className={'title'}>
						{app.name}
					</Typography>
				</Toolbar>
			</AppBar>
			
			<Box sx={{width: '100%'}} style={{visibility: loading ? 'visible' : 'hidden'}}> <LinearProgress color={'secondary'}/> </Box>
			
			<Table aria-label='Scores'>
				<TableHead>
					<TableRow>
						<TableCell>User</TableCell>
						<TableCell align='center'>Total Answers</TableCell>
						<TableCell align='center'>Total Correct Answers</TableCell>
						<TableCell align='center'>Scores</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{(scores && scores.length > 0) && scores.map((row, i) => (
						<TableRow key={`tr${i}`}>
							<TableCell component='th' scope='row'>
								<div className={'user'}>
									<Avatar
										variant={'square'}
										alt={row.user.displayName}
										src={row.user.profileImageUrl}
									/>
									<Typography className={'user-name'}>{row.user.displayName}</Typography>
								</div>
							</TableCell>
							<TableCell align='center'>{row.totalAnswers}</TableCell>
							<TableCell align='center'>{row.correctAnswers}</TableCell>
							<TableCell align='center'>{row.totalScores}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Dialog>
	)
}

ScoresPopup.propTypes = {
	app: PropTypes.object.isRequired,
	onClose: PropTypes.func,
	open: PropTypes.bool
}

ScoresPopup.defaultProps = {
	open: false
}