import React, {useContext, useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import AuthLayout from './AuthLayout';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Alert from '@mui/material/Alert';
import authService from '../AuthService';
import UserContext from '../../common/UserContext';
import {LoadingButton} from '@mui/lab';

export default function SignIn() {
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [, setUser] = useContext(UserContext);
	
	/**
	 * Send data to the server.
	 */
	const handleSubmit = () => {
		setError(() => null);
		setLoading(() => true);
		authService
			.authenticate(email, password)
			.then((user) => setUser(user))
			.then(() => navigate('/'))
			.catch(e => {
				setError(() => e.toString());
				setLoading(() => false);
			});
	};
	
	return (
		<AuthLayout title={'Sign In'}>
			<ValidatorForm noValidate onSubmit={() => handleSubmit()}>
				<TextValidator
					variant='outlined'
					margin='normal'
					required
					fullWidth
					id='email'
					label='Email Address'
					name='email'
					autoComplete='email'
					autoFocus
					value={email}
					validators={['required', 'isEmail']}
					errorMessages={['this field is required', 'email is not valid']}
					onChange={event => setEmail(event.target.value)}
				/>
				<TextValidator
					variant='outlined'
					margin='normal'
					required
					fullWidth
					name='password'
					label='Password'
					type='password'
					id='password'
					autoComplete='current-password'
					value={password}
					validators={['required']}
					errorMessages={['this field is required']}
					onChange={event => setPassword(event.target.value)}
				/>
				<LoadingButton
					type='submit'
					fullWidth
					variant='contained'
					color='primary'
					className={'submit'}
					loading={loading}
				>
					Sign In
				</LoadingButton>
				
				{error && <Alert severity='error' variant='filled'>{error}</Alert>}
				
				<Grid container>
					<Grid item xs>
						<Link component={RouterLink} to='../forgot-password' variant='body2'>
							Forgot password?
						</Link>
					</Grid>
				</Grid>
			</ValidatorForm>
		</AuthLayout>
	);
}
