import React from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import api from '../../common/ApiRequest';
import PropTypes from 'prop-types';
import AjaxButton from '../../common/buttons/AjaxButton';

export default function StartButton(props) {
	return <AjaxButton
		ariaLabel={'start'}
		color={'secondary'}
		exec={() => api.post(`/apps/quiz/${props.appId}/start`)}
		icon={PlayArrowIcon}
		{...props}
	/>
}

StartButton.propTypes = {
	title: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	onError: PropTypes.func
}

StartButton.defaultProps = {
	title: 'Start',
	disabled: false
}