import React, {useEffect, useState} from 'react';
import Link from '@mui/material/Link';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import AuthLayout from './AuthLayout';
import Alert from '@mui/material/Alert';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import authService from '../AuthService';
import Box from '@mui/material/Box';
import {LoadingButton} from '@mui/lab';

let validatorSet = false;

export default function ResetPassword(props) {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	
	const [email, setEmail] = useState(null);
	const [hash, setHash] = useState(null);
	const [password, setPassword] = useState('');
	const [password2, setPassword2] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [success, setSuccess] = useState(false);
	
	const handleSubmit = () => {
		setError(() => null);
		setLoading(() => true);
		authService
			.resetPassword(email, hash, password)
			.then(() => setSuccess(() => true))
			.catch(e => setError(() => e.toString()))
			.finally(() => setLoading(() => false));
	};
	
	/**
	 * Validate input params.
	 */
	useEffect(() => {
		let email = searchParams.get('email');
		let hash = searchParams.get('hash');
		if (!email || !hash) {
			setError('Invalid password reset URL');
		} else {
			setEmail(() => email);
			setHash(() => hash);
		}
		
	}, [props]);
	
	/**
	 * Password match validation.
	 */
	useEffect(() => {
		if (!validatorSet) {
			validatorSet = true;
			ValidatorForm.addValidationRule('isPasswordMatch', value => value === password);
		}
		return () => {
			validatorSet = false;
			ValidatorForm.removeValidationRule('isPasswordMatch');
		};
	});
	
	return (
		<AuthLayout title={'Reset Password'}>
			<ValidatorForm noValidate onSubmit={() => handleSubmit()}>
				{success && (
					<Box mt={3} mb={4}>
						<Alert severity='success'>Your password has been reset</Alert>
					</Box>
				)}
				{error && (
					<Box mt={3} mb={4}>
						<Alert severity='error' variant='filled'>{error}</Alert>
					</Box>
				)}
				{!success && !error && (<>
					<TextValidator
						variant='outlined'
						margin='normal'
						required
						fullWidth
						name='password'
						label='New Password'
						type='password'
						id='password'
						autoComplete='current-password'
						value={password}
						validators={['required', 'minStringLength:6']}
						errorMessages={['this field is required', 'password should be minimum 6 characters']}
						onChange={event => setPassword(event.target.value)}
					/>
					<TextValidator
						variant='outlined'
						margin='normal'
						required
						fullWidth
						name='password2'
						label='Confirm New Password'
						type='password'
						id='password2'
						value={password2}
						autoComplete='current-password'
						validators={['isPasswordMatch']}
						errorMessages={['passwords do not match']}
						onChange={event => setPassword2(event.target.value)}
					/>
					<LoadingButton
						type='submit'
						fullWidth
						variant='contained'
						color='primary'
						className={'submit'}
						loading={loading}
					>
						Submit
					</LoadingButton>
				</>)}
				<Box>
					<Link component={RouterLink} to='../signin' variant='body2'>
						{'Sign In'}
					</Link>
				</Box>
			</ValidatorForm>
		</AuthLayout>
	);
}