import React, {useContext, useState} from 'react';
import ImagePicker from '../../common/components/media/ImagePicker';
import {Accordion, AccordionDetails, Button, Typography} from '@mui/material';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import SettingsFormControl from '../../common/components/settings/SettingsFormControl';
import Paper from '@mui/material/Paper';
import UserIdContext from '../../common/UserIdContext';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import OptionsChat from './OptionsChat';
import Notification from '../../common/components/Notification';
import AppColorPicker from '../../common/components/settings/AppColorPicker';
import PollScreen from '../../common-libs/poll/PollScreen';
import Scale from '../../common/components/Scale';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SizePicker from '../../common/components/settings/SizePicker';
import objectPath from 'object-path';

const getPreviewData = (question) => {
	const data = {...question};
	data.options = data.options.map(o => {
		const option = {...o};
		option.votes = 1 + Math.floor(Math.random() * 10);
		return option;
	});
	data.total = 100;
	
	return data;
}

export default function QuestionForm(props) {
	const [question, setQuestion] = useState(props.question);
	const [error, setError] = useState('');
	const [userId] = useContext(UserIdContext);
	const [firstTabOpen, setFirstTabOpen] = useState(!question.text);
	const [previewId, setPreviewId] = useState('preview');
	const [previewData, setPreviewData] = useState(getPreviewData(question));
	
	const onChange = (key, value) => {
		const newQuestion = {...question};
		objectPath.set(newQuestion, key, value);
		setQuestion(() => newQuestion);
		setPreviewId(() => 'preview' + Math.random());
		setPreviewData(() => getPreviewData(newQuestion));
	};
	
	const onSubmit = () => {
		if (question.options.length < 2) {
			setError('At least 2 options are required');
		} else {
			props.onChange(question);
		}
	};
	
	return (
		<Grid container spacing={2} className={'poll-question-form'}>
			<Grid item xs={3}>
				
				{error && <Notification severity={'error'} message={error} onClear={() => setError(null)}/>}
				
				<ValidatorForm noValidate autoComplete='off' onSubmit={onSubmit} onKeyPress={e => e.key === 'Enter' && e.preventDefault()}>
					
					<Accordion expanded={firstTabOpen} onChange={() => setFirstTabOpen(!firstTabOpen)}>
						<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
							<Typography className={'typography'}>General</Typography>
						</AccordionSummary>
						
						<AccordionDetails className={'accordion-content'}>
							<SettingsFormControl>
								<TextValidator
									fullWidth
									required
									variant='outlined'
									label='Question'
									validators={['required']}
									errorMessages={['Question is required']}
									value={question.text}
									onChange={e => onChange('text', e.target.value)}
								/>
							</SettingsFormControl>
							
							<FormControl variant={'outlined'} fullWidth className={'select'}>
								<InputLabel required style={{backgroundColor: '#fff'}}>Chart Type</InputLabel>
								<Select required value={question.chartType} onChange={e => onChange('chartType', e.target.value)}>
									<MenuItem value={'bars'}>Bars</MenuItem>
									<MenuItem value={'pie'}>Pie</MenuItem>
								</Select>
							</FormControl>
							
							<SettingsFormControl variant={'outlined'}>
								<InputLabel style={{backgroundColor: '#fff'}}>Chart Position</InputLabel>
								<Select
									value={question.chartPosition}
									onChange={e => onChange('chartPosition', e.target.value)}
								>
									<MenuItem value={'left'} key={'chl'}>Left</MenuItem>
									<MenuItem value={'center'} key={'chc'}>Center</MenuItem>
									<MenuItem value={'right'} key={'chr'}>Right</MenuItem>
								</Select>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<TextValidator
									fullWidth
									variant='outlined'
									label='CSS Class'
									value={question.cssClass}
									onChange={e => onChange('cssClass', e.target.value)}
								/>
							</SettingsFormControl>
						
						</AccordionDetails>
					</Accordion>
					
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
							<Typography className={'typography'}>Options</Typography>
						</AccordionSummary>
						<AccordionDetails className={'accordion-content'}>
							<OptionsChat
								onChange={options => onChange('options', options)}
								options={question.options}
							/>
						</AccordionDetails>
					</Accordion>
					
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
							<Typography className={'typography'}>Votes Counter</Typography>
						</AccordionSummary>
						<AccordionDetails className={'accordion-content'}>
							<FormControl variant={'outlined'} fullWidth className={'select'}>
								<InputLabel required style={{backgroundColor: '#fff'}}>Display</InputLabel>
								<Select required value={question.counter.display} onChange={e => onChange('counter.display', e.target.value)}>
									<MenuItem value={true}>Yes</MenuItem>
									<MenuItem value={false}>No</MenuItem>
								</Select>
							</FormControl>
							
							<SettingsFormControl variant={'outlined'}>
								<InputLabel style={{backgroundColor: '#fff'}}>Vertical Position</InputLabel>
								<Select
									value={question.counter.vPosition}
									onChange={e => onChange('counter.vPosition', e.target.value)}
								>
									<MenuItem value={'top'} key={'vt'}>Top</MenuItem>
									<MenuItem value={'center'} key={'vc'}>Center</MenuItem>
									<MenuItem value={'bottom'} key={'vb'}>Bottom</MenuItem>
								</Select>
							</SettingsFormControl>
							
							<SettingsFormControl variant={'outlined'}>
								<InputLabel style={{backgroundColor: '#fff'}}>Horizontal Position</InputLabel>
								<Select
									value={question.counter.hPosition}
									onChange={e => onChange('counter.hPosition', e.target.value)}
								>
									<MenuItem value={'left'} key={'hl'}>Left</MenuItem>
									<MenuItem value={'center'} key={'hc'}>Center</MenuItem>
									<MenuItem value={'right'} key={'hr'}>Right</MenuItem>
								</Select>
							</SettingsFormControl>
							
							<SizePicker
								value={question.counter.fontSize}
								min={30}
								max={100}
								onChange={v => onChange('counter.fontSize', v)}
							/>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Text Color'
									value={question.counter.color}
									TextFieldProps={{value: question.counter.color}}
									onChange={value => value && onChange('counter.color', value)}
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Background Color'
									value={question.counter.bgColor}
									TextFieldProps={{value: question.counter.bgColor}}
									onChange={value => value && onChange('counter.bgColor', value)}
								/>
							</SettingsFormControl>
							
							<SizePicker
								label='Border Size'
								value={question.counter.borderSize}
								min={0}
								max={50}
								onChange={v => onChange('counter.borderSize', v)}
							/>
							
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Border Color'
									value={question.counter.borderColor}
									TextFieldProps={{value: question.counter.borderColor}}
									onChange={value => value && onChange('counter.borderColor', value)}
								/>
							</SettingsFormControl>
							
							<SizePicker
								label='Padding'
								value={question.counter.padding}
								min={0}
								max={50}
								onChange={v => onChange('counter.padding', v)}
							/>
							
							<SizePicker
								label='Margin'
								value={question.counter.margin}
								min={0}
								max={360}
								onChange={v => onChange('counter.margin', v)}
							/>
						
						</AccordionDetails>
					</Accordion>
					
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
							<Typography className={'typography'}>Background</Typography>
						</AccordionSummary>
						<AccordionDetails className={'accordion-content'}>
							<SettingsFormControl>
								<AppColorPicker
									variant='outlined'
									label='Background Color'
									value={question.bgColor}
									TextFieldProps={{value: question.bgColor}}
									onChange={value => value && onChange('bgColor', value)}
								/>
							</SettingsFormControl>
							
							<SettingsFormControl>
								<ImagePicker
									onRemove={() => onChange('background', null)}
									onSelect={img => onChange('background', img)}
									img={question.background}
									title={'Select Background Image'}
									userId={userId}
									className={'img-box'}
								/>
							</SettingsFormControl>
						</AccordionDetails>
					</Accordion>
					
					<Paper className={'save-box'}>
						<Button className={'save-btn'} variant={'contained'} type={'submit'}>OK</Button>
						<Button color={'secondary'} variant={'contained'} onClick={() => props.onCancel()}>Cancel</Button>
					</Paper>
				</ValidatorForm>
			</Grid>
			
			<Grid item xs={9}>
				{question.options.length >= 2 && (
					<Scale selector={'.app-screen'} className={'preview'} delay={5} key={previewId}>
						<PollScreen poll={previewData}/>
					</Scale>
				)}
			</Grid>
		</Grid>
	);
}

QuestionForm.propTypes = {
	question: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired
}
