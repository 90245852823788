import {Checkbox, Paper, Tooltip} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {FixedSizeList as List} from 'react-window';
import FormControlLabel from '@mui/material/FormControlLabel';
import api from '../../common/ApiRequest';
import PropTypes from 'prop-types';
import objectPath from 'object-path';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import PauseIcon from '@mui/icons-material/Pause';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import CheckIcon from '@mui/icons-material/Check';
import MovieFilterIcon from '@mui/icons-material/MovieFilter';
import moment from 'moment';
import ChatIcon from '@mui/icons-material/Chat';
import PersonIcon from '@mui/icons-material/Person';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import {grey} from '@mui/material/colors';
import Box from '@mui/material/Box';

function ChatLog(log) {
	let description = 'No actions applied';
	let Icon = ChatIcon;
	let iconColor = 'info';
	if (log.action === 'answer' && log.correct) {
		iconColor = 'success';
		description = 'User answered correctly';
	} else if (log.action === 'answer' && !log.correct) {
		iconColor = 'error';
		description = 'User answered incorrectly';
	} else if (log.action === 'team') {
		if (log.prevTeam) {
			iconColor = 'error';
			description = `User is already in team '${log.prevTeam.name}'`;
		} else {
			iconColor = 'success';
			description = `User joined team '${log.team.name}'`;
		}
	}
	
	return <div className={'log-line chat-log-line'}>
		<div className={'time'}>{moment(Date.parse(log.createdAt)).format('hh:mm:ss')}</div>
		<Tooltip title={description}><Icon color={iconColor}/></Tooltip>
		<div className={'user-name'}>{objectPath.get(log, 'msg.authorDetails.displayName')}</div>
		<div className={'msg'}>{objectPath.get(log, 'msg.displayMessage')}</div>
	</div>
}

function GameLog(log) {
	
	let text = log.action;
	if (log.action === 'screen') {
		text = `${log.screen} Screen`;
	} else if (log.action === 'leaderboard-view' && log.type === 'user') {
		text = 'user leaderboard';
	} else if (log.action === 'leaderboard-view' && log.type === 'team') {
		text = 'team leaderboard';
	}
	
	const defaultColor = grey[400];
	
	return <div className={'log-line game-log-line'}>
		
		<div className={'time'}>{moment(Date.parse(log.createdAt)).format('hh:mm:ss')}</div>
		
		{log.action === 'start' && <PlayArrowIcon style={{color: defaultColor}}/>}
		{log.action === 'stop' && <StopIcon style={{color: defaultColor}}/>}
		{log.action === 'next' && <SkipNextIcon style={{color: defaultColor}}/>}
		{log.action === 'pause' && <PauseIcon style={{color: defaultColor}}/>}
		{log.action === 'resume' && <NotStartedIcon style={{color: defaultColor}}/>}
		{log.action === 'complete' && <CheckIcon color={'success'}/>}
		{log.action === 'screen' && <MovieFilterIcon style={{color: defaultColor}}/>}
		{(log.action === 'leaderboard-view' && log.type === 'user') && <PersonIcon style={{color: defaultColor}}/>}
		{(log.action === 'leaderboard-view' && log.type === 'team') && <PeopleAltIcon style={{color: defaultColor}}/>}
		
		<div className={'action'}>{text}</div>
	</div>;
}

function DefaultLog(log) {
	return <div className={'log-line game-log-line'}>
		<div className={'time'}>{moment(Date.parse(log.createdAt)).format('hh:mm:ss')}</div>
		{log.action}
	</div>
}

export default function ActivityLog(props) {
	const [logs, setLogs] = useState([]);
	const [autoscroll, setAutoScroll] = useState(true);
	const listRef = useRef(null);
	const socket = props.socket;
	
	const scrollToBottom = () => listRef?.current.scrollToItem(logs.length);
	
	const onSocketLogs = (logs) => {
		setLogs(prev => {
			const maxSize = 10000;
			const newArray = [...prev, ...logs];
			if (newArray.length > maxSize) {
				newArray.splice(0, newArray.length - maxSize);
			}
			return newArray;
		});
	}
	
	useEffect(() => {
		autoscroll && scrollToBottom();
	}, [logs]);
	
	useEffect(() => {
		let active = true;
		
		(async () => {
			try {
				const logs = await api.get(`/apps/quiz/${props.quizId}/activity`);
				if (active) {
					logs.reverse();
					setLogs(logs);
				}
			} catch (e) {
				props.onError && props.onError(e);
			}
		})();
		
		return () => active = false;
	}, []);
	
	useEffect(() => {
		socket && socket.on('logs', onSocketLogs);
		return () => socket && socket.off('logs', onSocketLogs);
	}, [socket]);
	
	const renderRow = props => {
		const {index, style} = props;
		const log = logs[index];
		
		let component = DefaultLog;
		if (log.group === 'chat') {
			component = ChatLog;
		} else if (log.group === 'game') {
			component = GameLog;
		}
		
		return <div style={style} key={index}>{component(log)}</div>;
	}
	
	return (
		<Paper className={'sidebar activity-log'}>
			<List
				height={650}
				itemCount={logs.length}
				itemSize={30}
				width={'100%'}
				ref={listRef}
			>
				{renderRow}
			</List>
			
			<Box mt={1.5}>
				<FormControlLabel control={<Checkbox checked={autoscroll} onClick={e => setAutoScroll(e.target.checked)}/>} label={'Auto scroll to bottom'}/>
			</Box>
		</Paper>
	);
}

ActivityLog.propTypes = {
	quizId: PropTypes.string.isRequired,
	onError: PropTypes.func,
	socket: PropTypes.object
}