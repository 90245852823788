import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Dialog, DialogTitle, useTheme} from '@mui/material';
import ImageDialogBody from './ImageDialogBody';
import VideoDialogBody from './VideoDialogBody';
import Notification from '../Notification';
import auth from '../../../auth/AuthService';
import objectPath from 'object-path';

export default function MediaGallery(props) {
	const theme = useTheme();
	const tabs = [];
	const [error, setError] = useState(null);
	const media = props.media;
	const user = auth.getCurrentUser();
	
	if (props.types.includes('image')) {
		tabs.push(
			{type: 'user-images', title: 'My Images'},
			{type: 'standard-images', title: 'Standard Images'}
		);
	}
	if (props.types.includes('video') && (user.role === 'admin' || !!objectPath.get(user, 'permissions.video'))) {
		tabs.push({type: 'videos', title: 'Videos'});
	}
	
	const initialTab = media ? tabs.findIndex(t => ((media.mediaType === 'video' && t.type === 'videos') || (media.mediaType === 'image' && t.type !== 'videos'))) : 0;
	const [tab, setTab] = useState(initialTab);
	const currentTab = tabs[tab];
	
	const changeTab = (e, newTab) => {
		setTab(newTab);
	}
	
	const onClose = () => {
		props.onClose && props.onClose();
	}
	
	const onError = e => {
		setError(e);
	}
	
	const onSelect = (mediaType, object) => {
		if (!props.onSelect) {
			return;
		}
		mediaType === 'video' ? props.onSelect({mediaType, video: object}) : props.onSelect({mediaType, image: object});
	}
	
	return (
		<Dialog open={props.open} fullWidth={true} maxWidth={'lg'} onClose={onClose}>
			<DialogTitle style={{padding: theme.spacing(1)}}>
				<Tabs
					centered
					value={tab}
					onChange={changeTab}
					aria-label='Media Gallery'
				>
					{tabs.map((t, i) => <Tab label={t.title} key={`tab-${i}`} id={`tab-${i}`} aria-controls={`tabpanel-${i}`}/>)}
				</Tabs>
			</DialogTitle>
			{['user-images', 'standard-images'].includes(currentTab.type) && <ImageDialogBody
				key={currentTab.type}
				userId={currentTab.type === 'user-images' ? props.userId : null}
				onError={onError}
				onClose={onClose}
				onSelect={img => onSelect('image', img)}
				selected={media && media.mediaType === 'image' ? media.image : null}
			/>}
			
			{currentTab.type === 'videos' && <VideoDialogBody
				userId={props.userId}
				onError={onError}
				onClose={onClose}
				onSelect={video => onSelect('video', video)}
				selected={media && media.mediaType === 'video' ? media.video : null}
			/>}
			
			{error && <Notification
				severity={'error'}
				message={error}
				onClear={() => setError(null)}
			/>}
		</Dialog>
	);
}

MediaGallery.propTypes = {
	open: PropTypes.bool,
	media: PropTypes.object,
	types: PropTypes.array,
	userId: PropTypes.string.isRequired
}

MediaGallery.defaultProps = {
	open: true,
	types: ['image', 'video']
}