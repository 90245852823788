import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import {Typography} from '@mui/material';
import moment from 'moment';
import EditButton from '../buttons/EditButton';
import ControlRoomButton from '../buttons/ControlRoomButton';
import MoreActionsButton from '../buttons/MoreActionsButton';
import Status from './Status';
import PropTypes from 'prop-types';
import DeleteButton from '../../common/buttons/DeleteButton';
import authService from '../../auth/AuthService';
import LivestreamsTableCell from '../../common/components/table/LivestreamsTableCell'

export default function QuizTable(props) {
	const apps = props.apps;
	const user = authService.getCurrentUser();
	return (
		<TableContainer component={props.component}>
			<Table aria-label='My Quizzes' className={'app-table'}>
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						{user.role !== 'creator' && <TableCell>User</TableCell>}
						<TableCell>Live Streams</TableCell>
						<TableCell>Created</TableCell>
						<TableCell align={'center'}>Status</TableCell>
						<TableCell align='center'>Actions</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{(apps && apps.length === 0) && <TableRow>
						<TableCell colSpan={user.role !== 'creator' ? 6 : 5}><Typography align={'center'}>Nothing found</Typography></TableCell>
					</TableRow>}
					{apps && apps.map(app => (
						<TableRow key={app._id}>
							<TableCell>
								{app.name}
							</TableCell>
							{user.role !== 'creator' && <TableCell>{app.user && app.user.name}</TableCell>}
							
							<LivestreamsTableCell livestreams={app.livestreams}/>
							
							<TableCell>
								{moment(Date.parse(app.createdAt)).format('LLL')}
							</TableCell>
							<TableCell className={'status-cell'}>
								<Status model={app}/>
							</TableCell>
							<TableCell>
								<div className={'actions'}>
									<ControlRoomButton app={app}/>
									<EditButton app={app}/>
									<DeleteButton
										apiUrl={`/apps/quiz/${app._id}`}
										message={'Are you sure you want to delete this quiz? This action is irreversible.'}
										onError={props.onDeleteError}
										onSuccess={props.onDeleteSuccess}
									/>
									<MoreActionsButton app={app}/>
								</div>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

QuizTable.propTypes = {
	apps: PropTypes.array,
	component: PropTypes.any,
	onDelete: PropTypes.func,
	onSearchParamsChange: PropTypes.func.isRequired,
	searchParams: PropTypes.object
}

QuizTable.defaultProps = {
	component: Paper,
	searchParams: {}
}