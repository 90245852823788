import React, {useEffect, useState} from 'react';
import objectPath from 'object-path';
import api from '../common/ApiRequest';
import PagePreloader from '../common/components/PagePreloader';
import Notification from '../common/components/Notification';
import MainContainer from '../common/components/MainContainer';
import Grid from '@mui/material/Grid';
import {Box, Paper, Typography} from '@mui/material';
import Status from './components/Status';
import {useNavigate, useParams} from 'react-router-dom';
import ClipboardButton from '../common/buttons/ClipboardButton';
import StartStopButton from './buttons/StartStopButton';
import Scale from '../common/components/Scale';
import StreamLink from '../common/components/StreamLink';
import ScoresButton from './buttons/ScoresButton';
import io from 'socket.io-client';
import PauseResumeButton from './buttons/PauseResumeButton';
import QuizTabs from './components/QuizTabs';
import AppBar from '@mui/material/AppBar';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import AjaxButton from '../common/buttons/AjaxButton';
import ActivityLogButton from './buttons/ActivityLogButton';
import ActivityLog from './components/ActivityLog';
import ScreenProgress from './components/ScreenProgress';

function isRunning(model) {
	return !!model.serverUrl && ['running', 'paused'].includes(model.status);
}

function ControlRoom(props) {
	const navigate = useNavigate();
	const params = useParams();
	const [message, setMessage] = useState(null);
	const [model, setModel] = useState(props.model);
	const [loading, setLoading] = useState(false);
	const [starting, setStarting] = useState(false);
	const [iframeV, setIframeV] = useState(1);
	const [activityVisible, setActivityVisible] = useState(true);
	const [screenState, setScreenState] = useState(null);
	const [previewHeight, setPreviewHeight] = useState(720);
	const [scoreScreenType, setScoreScreenType] = useState(null);
	const [socket, setSocket] = useState(null);
	
	const totalScores = objectPath.get(model, 'leaderboard.total', 0);
	
	const onStatusChange = type => {
		if (type === 'start') {
			setStarting(() => true);
		}
		setLoading(() => true);
	}
	
	const onGameMessage = cmd => {
		if (cmd.action === 'screen') {
			if (!loading) {
				setLoading(() => true);
			}
			setScreenState(() => cmd);
			
			if (cmd.scoreScreenType) {
				setScoreScreenType(() => cmd.scoreScreenType);
			}
		}
	}
	
	const onSocketDisconnect = () => {
		setLoading(() => true)
	}
	
	useEffect(() => {
		
		setSocket(socket => {
			if (!socket && model.serverUrl) {
				socket = io(`${model.serverUrl}/?quizId=${model._id}&activity=1&leaderboard=1`, {transports: ['websocket']});
			}
			
			socket.on('cmd', onGameMessage);
			socket.on('leaderboard:change', () => setTimeout(() => setLoading(true), 250));
			socket.on('game:end', () => setTimeout(() => setLoading(true), 250));
			socket.on('disconnect', onSocketDisconnect);
			
			return socket;
		});
		
		return () => {
			if (socket) {
				socket.removeAllListeners()
				socket.disconnect();
			}
		}
	}, []);
	
	/**
	 * Wait till quiz is started.
	 */
	useEffect(() => {
		if (starting) {
			const interval = setInterval(() => setLoading(true), 2000);
			return () => clearInterval(interval);
		}
	}, [starting]);
	
	/**
	 * Connect to the game server when quiz is started.
	 */
	useEffect(() => {
		if (isRunning(model)) {
			setStarting(() => false);
			setIframeV(() => iframeV + 1);
		} else {
			setScreenState(() => null);
		}
	}, [isRunning(model)]);
	
	/**
	 * Reload quiz data on demand.
	 */
	useEffect(() => {
		if (loading) {
			setLoading(() => false);
			(async () => {
				try {
					const quiz = await api.get(`/apps/quiz/${params.quizId}?scores=1`);
					setLoading(() => false);
					setModel(() => quiz);
					setStarting(() => quiz.status === 'starting');
					
					if (!scoreScreenType) {
						setScoreScreenType(() => quiz.defaultScoreScreen);
					}
					
				} catch (e) {
					setMessage(() => e.toString());
				}
			})();
		}
	}, [loading]);
	
	return (
		<div className={'app-control-room quiz'}>
			
			<Notification
				message={message}
				severity={'error'}
				onClear={() => setMessage(null)}
			/>
			
			<MainContainer maxWidth={activityVisible ? 'xl' : 'lg'}>
				<AppBar position={'sticky'} color={'default'} className={'app-bar'}>
					<QuizTabs onClick={path => navigate(`/quiz/${params.quizId}/${path}`)} selected={'control-room'} mode={'edit'}/>
				</AppBar>
				
				<Grid container spacing={2}>
					<Grid item xs={activityVisible ? 2 : 3}>
						<Paper className={'sidebar'}>
							<Box mb={2.5}>
								<Status model={model}/>
							</Box>
							
							<Typography variant={'subtitle2'} color={'secondary'} align={'center'} mb={1.5}>{model.name}</Typography>
							
							<Typography variant={'subtitle2'} color={'primary'} className={`title icon-title`}>
								Source URL
								<ClipboardButton value={model.clientUrl}/>
							</Typography>
							
							<Box mb={2.5}>
								<a href={model.clientUrl} target={'_blank'} rel={'noreferrer'} className={'ellipsis'}>
									<Typography variant={'body2'}>{model.clientUrl}</Typography>
								</a>
							</Box>
						</Paper>
						
						{(model.livestreams && model.livestreams.length > 0) && (
							<Paper className={'sidebar'}>
								<Typography variant={'subtitle2'} color={'primary'} className={`title icon-title`} mb={1.5}>
									Live Streams
								</Typography>
								{model.livestreams && model.livestreams.map((livestream, i) => <div key={livestream.ytStreamId}>
									<Box mb={1}>
										<StreamLink livestream={livestream}/>
									</Box>
								</div>)}
							</Paper>
						)}
						
						<Paper className={'sidebar'}>
							<Typography variant={'subtitle2'} color={'primary'} className={`title icon-title`}>
								Leaderboard URL
								<ClipboardButton value={model.leaderboardUrl}/>
							</Typography>
							
							<Box mb={2.5}>
								<a href={model.leaderboardUrl} target={'_blank'} rel={'noreferrer'} className={'ellipsis'}>
									<Typography variant={'body2'}>{model.leaderboardUrl}</Typography>
								</a>
							</Box>
							
							{model.teamsLeaderboardUrl && <>
								<Typography variant={'subtitle2'} color={'primary'} className={`title icon-title`}>
									Team Leaderboard URL
									<ClipboardButton value={model.teamsLeaderboardUrl}/>
								</Typography>
								
								<Box mb={2.5}>
									<a href={model.teamsLeaderboardUrl} target={'_blank'} rel={'noreferrer'} className={'ellipsis'}>
										<Typography variant={'body2'}>{model.teamsLeaderboardUrl}</Typography>
									</a>
								</Box>
							</>}
							
							{model.teamPlayersUrl && <>
								<Typography variant={'subtitle2'} color={'primary'} className={`title icon-title`}>
									Team Players URL
									<ClipboardButton value={model.teamPlayersUrl}/>
								</Typography>
								
								<Box mb={2.5}>
									<a href={model.teamPlayersUrl} target={'_blank'} rel={'noreferrer'} className={'ellipsis'}>
										<Typography variant={'body2'}>{model.teamPlayersUrl}</Typography>
									</a>
								</Box>
							</>}
							
							{model.contactFormUrl && <>
								<Typography variant={'subtitle2'} color={'primary'} className={`title icon-title`}>
									Contact Form URL
									<ClipboardButton value={model.contactFormUrl}/>
								</Typography>
								
								<Box mb={2.5}>
									<a href={model.contactFormUrl} target={'_blank'} rel={'noreferrer'} className={'ellipsis'}>
										<Typography variant={'body2'}>{model.contactFormUrl}</Typography>
									</a>
								</Box>
							</>}
							
							<Typography variant={'subtitle2'} color={'primary'} className={'title'}>Question Progress</Typography>
							<Box mb={2.5}>
								<Typography variant={'body2'}>
									{model.numQuestionsAsked ? model.numQuestionsAsked : 0} / {(model.loopQuestions && model.questionsPerRound > 0) ? model.questionsPerRound : model.numQuestions}
								</Typography>
							</Box>
							
							<Typography variant={'subtitle2'} color={'primary'} className={`title icon-title`}>
								Participants
								{totalScores > 0 && <ScoresButton app={model}/>}
							</Typography>
							<Box mb={2.5}>
								<Typography variant={'body2'}>{totalScores}</Typography>
							</Box>
						</Paper>
					</Grid>
					
					<Grid item xs={activityVisible ? 6 : 9}>
						<Paper className={'paper'}>
							
							<AjaxButton
								color={'primary'}
								progressColor={'primary'}
								exec={() => api.post(`/apps/quiz/${model._id}/cmd`, {cmd: 'next'})}
								icon={SkipNextIcon}
								title={'Skip to next'}
								disabled={!isRunning(model)}
								onChange={onStatusChange}
								onError={e => setMessage(e.toString())}
							/>
							
							<PauseResumeButton
								app={model}
								onError={e => setMessage(e.toString())}
								onChange={onStatusChange}
								disabled={!isRunning(model)}
							/>
							
							<StartStopButton
								app={model}
								onError={e => setMessage(e.toString())}
								onChange={onStatusChange}
							/>
							
							<ActivityLogButton
								active={activityVisible}
								onClick={() => setActivityVisible(prev => !prev)}
							/>
							
							<AjaxButton
								color={'primary'}
								progressColor={'primary'}
								exec={() => api.post(`/apps/quiz/${model._id}/cmd`, {cmd: 'complete'})}
								icon={TaskAltIcon}
								title={'Complete Round'}
								disabled={!isRunning(model)}
								onChange={onStatusChange}
								onError={e => setMessage(e.toString())}
							/>
						</Paper>
						
						<Box mt={2}>
							<Box style={{height: '4px'}}>
								{(screenState && screenState.running && screenState.till) && <ScreenProgress
									key={screenState ? screenState.screen : 'screenProgress'}
									startTime={screenState.date}
									endTime={screenState.till}
								/>}
							</Box>
							
							<Box style={{maxHeight: `${previewHeight}px`, overflow: 'hidden'}}>
								<Scale selector={'iframe'} className={'preview'} originalWidth={1344} onScale={scale => setPreviewHeight(720 * scale)}>
									<iframe
										className={`iframe`}
										style={{width: '1344px'}}
										src={`${model.clientUrl}?v=${iframeV}`}
										frameBorder={0}
									></iframe>
								</Scale>
							</Box>
							<Typography variant={'caption'} style={{textAlign: 'center'}} component={'div'}>If video or audio is not playing - click anywhere in the quiz
								preview</Typography>
						
						</Box>
					</Grid>
					
					{activityVisible && <Grid item xs={4}>
						<ActivityLog
							quizId={model._id}
							socket={socket}
							onError={e => setMessage(e.toString())}
						/>
					</Grid>}
				</Grid>
			</MainContainer>
		</div>);
}

export default function PageLoader() {
	
	const params = useParams();
	const [model, setModel] = useState(null);
	const [error, setError] = useState(null);
	
	useEffect(() => {
		let active = true;
		(async () => {
			try {
				const model = await api.get(`/apps/quiz/${params.quizId}?scores=1`);
				active && setModel(model);
			} catch (e) {
				active && setError(e.toString());
			}
		})();
		
		return () => active = false;
	}, []);
	
	return model ?
		<ControlRoom model={model}/> :
		<PagePreloader error={error}/>;
}
