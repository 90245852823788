import React, {useState} from 'react';
import Link from '@mui/material/Link';
import {Link as RouterLink} from 'react-router-dom';
import AuthLayout from './AuthLayout';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import Grid from '@mui/material/Grid';
import authService from '../AuthService';
import {LoadingButton} from '@mui/lab';
import Box from '@mui/material/Box';

export default function ForgotPassword() {
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [success, setSuccess] = useState(false);
	
	const handleSubmit = () => {
		setError(() => null);
		setLoading(() => true);
		authService
			.sendPasswordResetLink(email)
			.then(() => setSuccess(() => true))
			.catch(e => setError(() => e.toString()))
			.finally(() => setLoading(() => false));
	};
	
	return (
		<AuthLayout title={'Forgot Password'}>
			<ValidatorForm noValidate onSubmit={() => handleSubmit()}>
				{success && (
					<Box mt={3} mb={4}>
						<Alert severity='success'><AlertTitle>Success</AlertTitle>We've sent you an email with the password reset link and instructions.</Alert>
					</Box>
				)}
				{!success && (<>
					<TextValidator
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='email'
						label='Email Address'
						name='email'
						autoComplete='email'
						autoFocus
						value={email}
						validators={['required', 'isEmail']}
						errorMessages={['this field is required', 'email is not valid']}
						onChange={event => setEmail(event.target.value)}
					/>
					
					<LoadingButton
						type='submit'
						fullWidth
						variant='contained'
						color='primary'
						className={'submit'}
						loading={loading}
					>
						Submit
					</LoadingButton>
					
					{error && <Alert severity='error' variant='filled'>{error}</Alert>}
				</>)}
				
				<Grid container>
					<Grid item xs>
						<Link component={RouterLink} to='../signin' variant='body2'>
							{'Sign In'}
						</Link>
					</Grid>
				</Grid>
			</ValidatorForm>
		</AuthLayout>
	);
}