import React from 'react';
import PropTypes from 'prop-types';
import {Autocomplete} from '@mui/lab';
import {Chip, TextField} from '@mui/material';

export default function ChipInput(props) {
	return (
		<Autocomplete
			multiple
			freeSolo
			options={[]}
			defaultValue={[]}
			disableClearable={true}
			value={props.options}
			required={props.required}
			onChange={(e, values) => props.onChange && props.onChange(values)}
			renderInput={params => (
				<TextField
					size={'small'}
					{...params}
					{...props.inputProps}
					label={props.label}
					required={props.required}
					error={!!props.errorMsg}
					helperText={props.errorMsg || props.inputProps.helperText}
				/>
			)}
			renderTags={(value, getTagProps) =>
				value.map((option, index) => (
					<Chip
						variant={'outlined'}
						label={option}
						size={'small'}
						{...getTagProps({index})}
					/>
				))
			}
		/>
	)
}

ChipInput.propTypes = {
	errorMsg: PropTypes.string,
	label: PropTypes.string,
	onChange: PropTypes.func,
	options: PropTypes.array,
	required: PropTypes.bool
}

ChipInput.defaultProps = {
	errorMsg: null,
	label: 'Tags',
	options: [],
	required: false
}